import React from 'react';
import Big from 'big.js';
import Grid from '@material-ui/core/Grid';
import find from 'lodash.find';
import deepEqual from 'react-fast-compare';
import startsWith from 'lodash.startswith';
import sortBy from 'lodash.sortby';
import cloneDeep from 'clone-deep';
import uuidv4 from 'uuid/v4';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import Skeleton from 'react-loading-skeleton';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import { mismatchInVersion, sessionTimedOut } from '../redux/modules/login/login-actions';
import { errorMessage } from '../redux/modules/message/message-actions';
import {
    addOrSubtractDays,
    addOrSubtractMinutes,
    addOrSubtractYears,
    formatDate,
    formatDateForDisplay,
    formatDateTimeForDisplay,
    formatTimeForDisplay,
    isDateBefore,
    isDateValid,
    setTimeForDate,
} from './DateUtil';
import { hideSpinner } from '../redux/modules/spinner/spinner';
import { checkIfPrivilegeExistsForUser } from './privilegeChecker';
import { addRetryRequest } from '../redux/modules/retryRequests/retryRequests-actions';
import {
    payerTypes,
} from '../containers/RegistrationAppComponents/QuickRegistration/QuickRegistrationForm/QuickRegistrationFormUtil';
import {
    getAnalyticsCurrencyNumericSystem,
    getAppVersion,
    getBillRoundingPlaces,
    getRoundingMethodology,
    getRoundingPlaces,
    roundingMethodologies,
} from './state';
import OutlinedTextField from '../components/OutlinedTextField';
import { getStringFromObject, setStringPropertyToObject } from './lodashUtils';
import { NumberOf } from './numberUtils';
import { isObjectValidAndNotEmpty } from './nullCheckUtils';

export const userNotLoggedInMessage = 'User not logged in..';
export const appVersionMismatchInMessage = 'App Version Has Been Updated. Please Refresh To Access The Latest Version.';

export const checkIfValuePresentInArrayOfObjectsForProperty = (
    valueToCheck,
    fieldToCheck,
    arrayOfObjects,
) => {
    for (let i = 0; i < arrayOfObjects.length; i += 1) {
        if (arrayOfObjects[i][fieldToCheck] === valueToCheck) {
            return true;
        }
    }
    return false;
};
export const isArrayValid = anArray => anArray && Array.isArray(anArray);
export const isArrayValidAndNotEmpty = anArray => anArray && Array.isArray(anArray) && anArray.length > 0;

export const sortArrayOfObjectsByFieldValue = (arr, field) => sortBy(arr, item => item[field]);

export const isStringNullOrUndefined = (stringToCheck) => {
    if (!stringToCheck) {
        return true;
    }
    if (stringToCheck === 'undefined') {
        return true;
    }
    if (stringToCheck === 'null') {
        return true;
    }
    return false;
};

export const smoothScroll = (offSet) => {
    const startY = window.pageYOffset;
    const stopY = offSet;
    const distance = stopY > startY ? stopY - startY : startY - stopY;
    if (distance < 100) {
        window.scrollTo(0, stopY);
        return;
    }
    let speed = Math.round(distance / 100);
    if (speed >= 20) {
        speed = 20;
    }
    const step = Math.round(distance / 75);
    let leapY = stopY > startY ? startY + step : startY - step;
    let timer = 0;
    if (stopY > startY) {
        for (let i = startY; i < stopY; i += step) {
            // eslint-disable-next-line no-loop-func
            setTimeout(() => window.scrollTo(0, leapY), timer * speed);
            leapY += step;
            if (leapY > stopY) {
                leapY = stopY;
            }
            timer += 1;
        }
        return;
    }
    for (let i = startY; i > stopY; i -= step) {
        // eslint-disable-next-line no-loop-func
        setTimeout(() => window.scrollTo(0, leapY), timer * speed);
        leapY -= step;
        if (leapY < stopY) {
            leapY = stopY;
        }
        timer += 1;
    }
};

export const formatCurrency = (dataFloat) => {
    let x = dataFloat.toString();
    let afterPoint = '';
    if (x.indexOf('.') > 0) afterPoint = x.substring(x.indexOf('.'), x.length);
    x = Math.floor(x);
    x = x.toString();
    let lastThree = x.substring(x.length - 3);
    const otherNumbers = x.substring(0, x.length - 3);
    if (otherNumbers !== '') lastThree = `, ${lastThree}`;
    const res =
        otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') +
        lastThree +
        afterPoint;
    return res;
};

export const findObjInArray = (array, obj) => find(array, obj);

export const isStartWith = (value, search, position) => startsWith(value, search, position);

export const checkIfImage = fileName => (fileName.endsWith('.jpg') ||
    fileName.endsWith('.png') ||
    fileName.endsWith('.JPG') ||
    fileName.endsWith('.jpeg') ||
    fileName.endsWith('.JPEG') ||
    fileName.endsWith('.PNG'));

export const createExternalLink = (externalLink, formvalues) => {
    if (externalLink && formvalues) {
        let link = externalLink;
        while (link.match(/#{([\w.]+)}/)) {
            const pathVar = link.match(/#{([\w.]+)}/);
            const value = getStringFromObject(pathVar[1], formvalues);
            link = link.replace(pathVar[0], value);
        }
        return link;
    }
    return false;
};

export const getCookieValue = (name) => {
    if (document.cookie.length > 0) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i += 1) {
            if (cookies[i].startsWith(name)) {
                return cookies[i].split('=')[1];
            }
        }
    }
    return '';
};

export const getErrorMessage = (errorObject) => {
    let message = 'Api Call Failed';
    if (errorObject) {
        const error = errorObject.response || errorObject;
        if (error && error.data && error.data.exception) {
            // eslint-disable-next-line prefer-destructuring
            message = error.data.exception;
        } /* else if (error) {
            message = error.message;
        } */
        console.log('asda-9--2', error);
        if (error.status === 401) {
            message = 'Session Timed Out. Please Reload To Login Again';
        }
        if (error.status === 426) {
            message = appVersionMismatchInMessage;
        }
    }
    return message;
};

export const getQManagementErrorMessage = (errorObject) => {
    let message = 'Api Call Failed';
    if (errorObject) {
        const error = errorObject.response || errorObject;
        if (error && error.data && error.data.message) {
            // eslint-disable-next-line prefer-destructuring
            message = error.data.message;
        }
        if (error.status === 401) {
            message = 'Session Timed Out. Please Reload To Login Again';
        }
    }
    return message;
};

export const sort = (arr, order) => {
    if (Array.isArray(arr)) {
        return arr.sort((a, b) => (a < b ? -1 * order : 1 * order));
    }
    return arr;
};

export const to24HourFormat = (time) => {
    let hours = Number(time.match(/^(\d+)/)[1]);
    const minutes = Number(time.match(/:(\d+)/)[1]);
    const AMPM = time.match(/\s(.*)$/)[1];
    if (AMPM === 'PM' && hours < 12) {
        hours += 12;
    }
    if (AMPM === 'AM' && hours === 12) {
        hours -= 12;
    }
    let sHours = hours.toString();
    let sMinutes = minutes.toString();
    if (hours < 10) {
        sHours = `0${sHours}`;
    }
    if (minutes < 10) {
        sMinutes = `0${sMinutes}`;
    }
    return `${sHours}:${sMinutes}`;
};

export const getSlotsForDuration = (slotDuration) => {
    let slotTime = slotDuration;
    console.log('hscmbnsxck.jdfsv', slotTime);
    if (slotDuration === 0) {
        slotTime = 30;
    }
    const times = [];
    let mins = 0;
    const maxMins = 24 * 60;
    while (mins <= maxMins) {
        if (maxMins === mins) {
            mins -= 1;
        }
        const hours = parseInt(mins / 60, 0);
        const minFraction = parseInt(mins % 60, 0);
        times.push(`${hours.toString().padStart(2, '0')}:${minFraction.toString().padStart(2, '0')}`);
        mins += slotTime;
    }
    // for (let hour = 0; hour < 24; hour += 1) {
    //     const hh = hour < 10 ? `0${hour}` : `${hour}`;
    //     for (let min = 0; min < 60; min += slotTime) {
    //         if (min < 10) {
    //             times.push(`${hh}:0${min}`);
    //         } else {
    //             times.push(`${hh}:${min}`);
    //         }
    //     }
    // }
    return times;
};

export const getNextSlot = (startTime, slotDuration) => {
    // Start time in 24hours
    let hours = parseInt(startTime.split(':')[0], 0);
    let minutes = parseInt(startTime.split(':')[1], 0) + slotDuration;
    if (minutes === 60) {
        hours += 1;
        minutes = '00';
    }
    if (hours < 10) {
        hours = `0${hours}`;
    }
    return `${hours}:${minutes}`;
};

export const getSlotsBetweenTime = (startTime, endTime, slotDuration) => {
    const slots = [];
    const now = new Date();
    if (startTime && endTime && slotDuration) {
        const fromDate = setTimeForDate(
            now,
            parseInt(startTime.split(':')[0], 0),
            parseInt(startTime.split(':')[1], 0),
        );
        const toDate = setTimeForDate(
            now,
            parseInt(endTime.split(':')[0], 0),
            parseInt(endTime.split(':')[1], 0),
        );
        while (fromDate < toDate) {
            const nextSlot = formatDate(fromDate, 'HH:mm');
            slots.push(nextSlot);
            fromDate.setMinutes(fromDate.getMinutes() + slotDuration);
        }
    }
    return slots;
};

export const to12HourFormat = (time) => {
    if (time) {
        const splitTime = time.split(':');
        if (splitTime && splitTime.length) {
            const hours = splitTime[0];
            const minutes = splitTime[1];
            let hours12 = (hours % 12) || 12;
            hours12 = hours12 < 10 ? `0${hours12}` : hours12;
            const ampm = hours < 12 ? 'AM' : 'PM';
            return `${hours12}:${minutes} ${ampm}`;
        }
    }
    return '';
};

export const padTimingWithZero = (timeString) => {
    const hourEnd = timeString.indexOf(':');
    const hours = `${timeString.substr(0, hourEnd)}`;
    const restTimeString = `${timeString.substr(hourEnd)}`;
    return `${hours.padStart(2, '0')}${restTimeString}`;
};

export const mergeObjectWitKeyAsStringNotation = (obj, dotNotation, value) => {
    const path = dotNotation.split('.');
    let temp = obj;
    let i = 0;
    for (i = 0; i < path.length - 1; i += 1) {
        if (temp[path[i]] === undefined) {
            temp[path[i]] = {};
            console.log('1235566yreed', temp, path[i]);
        }
        temp = temp[path[i]];
    }
    temp[path[i]] = value;
    return temp;
};

export const isValidObject = (objToTest) => {
    if (objToTest === null) return false;
    return typeof (objToTest) !== 'undefined';
};


export const getValueFromObjectByStringNotationKey = (obj, dotNotation) => {
    if (!isValidObject(obj) || Object.keys(obj).length === 0) {
        return null;
    }
    const path = dotNotation.split('.');
    let temp = obj;
    let i = 0;
    for (i = 0; i < path.length - 1; i += 1) {
        if (temp[path[i]] === undefined || temp[path[i]] === null) {
            return undefined;
        }
        temp = temp[path[i]];
    }
    if (!temp) return null;
    return temp[path[i]];
};

/* If choices are there in props for select field, radio button */
export const findValueByChoices = (choices, dataSourceConfig = {}, inputValue) => {
    const { value, text } = dataSourceConfig;
    console.log('safsdfsdfdasdfsdf', choices, dataSourceConfig, inputValue);
    const temp = choices.find(c => (
        getStringFromObject(value, c, c).toString().toLowerCase() === inputValue.toString().toLowerCase()));
    return temp && getStringFromObject(text, temp, temp);
};

function isArray(o) {
    return Object.prototype.toString.call(o) === '[object Array]';
}

// Assumes that target and source are either objects (Object or Array) or undefined
// Since will be used to convert to JSON, just reference objects where possible
export const mergeObjects = (t, s) => {
    const target = t;
    const source = s;
    let item;
    let tItem;
    let o;
    let idx;
    console.log('sdfsdfsdfsdfdsf', t, s);
    // If either argument is undefined, return the other.
    // If both are undefined, return undefined.
    if (typeof source === 'undefined') {
        return source;
    } else if (typeof target === 'undefined') {
        return target;
    }

    // Assume both are objects and don't care about inherited properties
    Object.keys(source).map((prop) => {
        item = source[prop];
        console.log('sdfsdfsdfdsf', prop, item);
        if (typeof item === 'object' && item !== null) {
            if (isArray(item) && item.length) {
                // deal with arrays, will be either array of primitives or array of objects
                // If primitives
                if (typeof item[0] !== 'object') {
                    // if target doesn't have a similar property, just reference it
                    tItem = target[prop];
                    if (!tItem) {
                        target[prop] = item;

                        // Otherwise, copy only those members that don't exist on target
                    } else {
                        // Create an index of items on target
                        o = {};
                        for (let i = 0, iLen = tItem.length; i < iLen; i += 1) {
                            o[tItem[i]] = true;
                        }

                        // Do check, push missing
                        for (let j = 0, jLen = item.length; j < jLen; j += 1) {
                            if (!(item[j] in o)) {
                                tItem.push(item[j]);
                            }
                        }
                    }
                } else {
                    // Deal with array of objects
                    // Create index of objects in target object using ID property
                    // Assume if target has same named property then it will be similar array
                    idx = {};
                    tItem = target[prop];
                    console.log('sdfsfsdfsdf', prop, tItem, target, item, source);
                    for (let k = 0, kLen = tItem.length; k < kLen; k += 1) {
                        idx[tItem[k].id] = tItem[k];
                    }

                    // Do updates
                    for (let l = 0, ll = item.length; l < ll; l += 1) {
                        // If target doesn't have an equivalent, just add it
                        if (!(item[l].id in idx)) {
                            tItem.push(item[l]);
                        } else {
                            mergeObjects(idx[item[l].id], item[l]);
                        }
                    }
                }
            } else {
                // deal with object
                mergeObjects(target[prop], item);
            }
        } else {
            // item is a primitive, just copy it over
            target[prop] = item;
        }
        return null;
    });
    return target;
};

export const labelRequired = () => (
    <span style={{ color: 'red' }}>&nbsp;*</span>
);

export const isValidDate = d => d instanceof Date && !Number.isNaN(d.getTime());

export const replaceValuesInString = (
    templateString,
    dataObject,
    type = 'default',
) => {
    let r = templateString.match(/\{(.*?)\}/g);
    if (type === 'tilda') {
        r = templateString.match(/~(.*?)~/g);
    }
    let templateWithReplacedValue = templateString;
    console.log('asds9adu09da', r);
    // eslint-disable-next-line no-unused-expressions
    r &&
    r.forEach((state) => {
        // eslint-disable-line
        let stateItem = state.split(/[{}]/g)[1]; // the item in the registration data
        if (type === 'curly') {
            // eslint-disable-next-line
            stateItem = state.split(/[{}]/g)[1]; // the item in the registration data
        } else if (type === 'tilda') {
            // eslint-disable-next-line
            stateItem = state.split(/[~~]/g)[1];
        } else if (type === 'hash') {
            // eslint-disable-next-line
            stateItem = state.split(/[##]/g)[1];
        }
        console.log('state item', stateItem);
        let valToReplace = state.replace(/\[/g, '\\[');
        valToReplace = valToReplace.replace(/\]/g, '\\]');
        const finalregex = new RegExp(valToReplace, 'g');
        console.log('from funcs ss s ss ', stateItem, getStringFromObject(stateItem, dataObject), dataObject);
        templateWithReplacedValue = templateWithReplacedValue.replace(
            finalregex,
            getStringFromObject(stateItem, dataObject),
        );
    });
    return templateWithReplacedValue;
};

export const sortObjArray = (arr, attr, aesc = true) => {
    if (Array.isArray(arr)) {
        const temp = [...arr];
        return temp.sort((a, b) => (aesc ? a[attr] - b[attr] : b[attr] - a[attr]));
    }
    return arr;
};

export const getNameOfEmployee = (data) => {
    console.log('sdfsfsdfsdf', data);
    let name = data.givenName ? `${data.givenName}` : '';
    name = data.middleName ? `${name} ${data.middleName}` : name;
    name = data.familyName ? `${name} ${data.familyName}` : name;
    return name;
};

export const isValidFunction = aFunction => (
    aFunction && typeof aFunction === 'function'
);

export const isEmpty = (value) => {
    const type = typeof value;
    if ((value !== null && type === 'object') || type === 'function') {
        const properties = Object.keys(value);
        if (properties.length === 0 || properties.size === 0) {
            return true;
        }
    }
    if (value != null && type === 'string') {
        return !value || value.length === 0;
    }
    return !value;
};

export const removeDuplicates = (arr) => {
    const t = {};
    const unique = [];
    arr.map((a) => {
        if (Object.prototype.hasOwnProperty.call(t, a)) {
            return null;
        }
        unique.push(a);
        t[a] = a;
        return null;
    });
    return unique;
};

export const extractTextFromDoubleQuotes = (str) => {
    const matches = str.match(/"(.*?)"/);
    return matches
        ? matches[1]
        : str;
};

export const clearAllDataAndReload = () => {
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
};
export const didApiCallFailDueToUnauthorizedUser = e => (
    e &&
    (e.status === 401 ||
        (e.response && e.response.status === 401)
        ||
        (e.response && e.response.message === userNotLoggedInMessage) ||
        (e.data && e.data.status === 401)
        ||
        (e.data && e.data.exception === userNotLoggedInMessage) ||
        (e.response && e.response.data && e.response.data.exception === userNotLoggedInMessage)
    )
);
export const didApiCallFailDueToVersionMismatch = e => (
    e &&
    (e.status === 426 ||
        (e.response && e.response.status === 426)
        ||
        (e.response && e.response.message === appVersionMismatchInMessage) ||
        (e.data && e.data.status === 426)
        ||
        (e.data && e.data.exception === appVersionMismatchInMessage) ||
        (e.response && e.response.data && e.response.data.exception === appVersionMismatchInMessage)
    )
);

/* Check if string is valid UUID */
export const checkIfValidUUID = (str) => {
    // Regular expression to check if string is a valid UUID
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    return regexExp.test(str);
};

export const getChartData = (chartData, x, y, label) => {
    if (isArrayValidAndNotEmpty(chartData)) {
        const chartDataVals = chartData.sort((a, b) => a.time - b.time);
        const chartValues = chartDataVals.map(data => (
            { x: new Date(NumberOf(data[x])), y: NumberOf(data[y]) }),
        );
        console.log('fdvnj dfmsv fsv', chartValues);
        return {
            label: label || '',
            values: chartValues,
        };
    }
    return {
        label: label || '',
        values: [],
    };
};
export const apiCatchBlockFunction = (error, dispatcher = () => {}, functionToCallAfterLogin, errorMsgGetter = getErrorMessage) => {
    dispatcher(hideSpinner());
    if (didApiCallFailDueToUnauthorizedUser(error)) {
        dispatcher(sessionTimedOut(functionToCallAfterLogin));
        dispatcher(addRetryRequest(error.config));
    }
    console.error('error occurred', { ...error });
    if (didApiCallFailDueToVersionMismatch(error)) {
        dispatcher(mismatchInVersion());
    }
    console.log('error occurred', error);
    dispatcher(errorMessage(errorMsgGetter(error)));
};

export const padStringWith = (value = '', padStart = true, padWith = '', maxPadLength = 0) => {
    const stringToBePadded = ''.concat(value);
    if (typeof maxPadLength === 'number') {
        return (padStart ? stringToBePadded.padStart(maxPadLength, padWith) :
            stringToBePadded.padEnd(maxPadLength, padWith));
    }
    return value;
};

export const isValidNumeric = aNumber => (
    (aNumber || aNumber === 0) && !isNaN(aNumber) //  eslint-disable-line
);
export const isValidNumber = aNumber => (
    (aNumber || aNumber === 0) && !Number.isNaN(aNumber) && typeof aNumber === 'number'
);

export const isValidNumberString = aNumber => (
    isValidNumeric(parseInt(aNumber, 10))
);

export const getStringForBoolean = (aBoolean) => {
    if (aBoolean) {
        if (typeof aBoolean === 'string') {
            return aBoolean.toLowerCase() === 'true' ? 'YES' : 'NO';
        }
        return 'YES';
    }
    return 'NO';
};

export const isValidText = aText => (
    (aText || aText === '') && typeof aText === 'string'
);
export const isValidTextAndNotEmpty = aText => (
    aText && typeof aText === 'string'
);

export const getQueueTabsToShow = (props) => {
    const {
        appConfiguration,
        tabNamesField,
    } = props;
    const queueTabs = getStringFromObject(tabNamesField, appConfiguration, {});
    let tabs = [];
    if (isObjectValidAndNotEmpty(queueTabs)) {
        tabs = tabs.concat(Object.values(queueTabs));
    }
    return tabs;
};
// name[0], returns 0 (name[0].anotherName[0] will not work with this method)
export const getIndexFromFieldName = (fieldName) => {
    if (fieldName) {
        const match = /\[([0-9]+)\]/g.exec(fieldName);
        if (match != null && match[1]) {
            return match[1];
        }
    }
    return null;
};

export const getInnerIndexFromFieldName = (fieldName, level=2) => {
    const regex = /\[(\d+)\]/g;
    let match;
    let currentLevel = 0;

    while ((match = regex.exec(fieldName)) !== null) {
        currentLevel++;
        if (currentLevel === level) {
            return parseInt(match[1], 10);
        }
    }

    return null;
};

export const findIndexOfValuePresentInArrayOfObjectsForProperty = (
    valueToCheck,
    fieldToCheck,
    arrayOfObjects,
) => {
    console.log('checking value dudde', arrayOfObjects);
    if (isArrayValidAndNotEmpty(arrayOfObjects)) {
        for (let i = 0; i < arrayOfObjects.length; i += 1) {
            if (arrayOfObjects[i][fieldToCheck] === valueToCheck) {
                return i;
            }
        }
    }
    return null;
};

export const calculateAge = (birthday) => { // birthday is a date
    if (isValidDate(birthday)) {
        const today = new Date();
        let age = today.getFullYear() - birthday.getFullYear();
        const m = today.getMonth() - birthday.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
            age -= 1;
        }
        return age;
    }
    return '';
};

const bigDecimalRoundHalfUpMode = 1;

export const roundedANumber = (
    value,
    digits = getRoundingPlaces(),
) => {
    const roundingMethodology = getRoundingMethodology();
    if (roundingMethodology && roundingMethodology === roundingMethodologies.MATHROUND) {
        return Math.round(NumberOf(value) * (10 ** digits)) / (10 ** digits);
    }
    return new Big(NumberOf(value)).round(digits, bigDecimalRoundHalfUpMode).toNumber();
};

export const roundedValue = (
    value,
    digits = getRoundingPlaces(),
) => {
    let newRoundedValue = NumberOf(value);
    let noOfIterations = digits + 2;
    while (noOfIterations >= digits) {
        newRoundedValue = roundedANumber(newRoundedValue, noOfIterations);
        noOfIterations -= 1;
    }
    return newRoundedValue;
};

export const valueToFixedTwoDigits = (value, digits = getRoundingPlaces()) => {
    if ((value || value === 0) && value.toFixed && typeof value.toFixed === 'function') {
        return value.toFixed(digits);
    }
    return value;
};

export const roundedValueFixedToTwoDigits = (
    value,
    digits = getRoundingPlaces(),
) => valueToFixedTwoDigits(roundedValue(value, digits), digits);

export const currencyFormatter = (value) => {
    const numericSystem = getAnalyticsCurrencyNumericSystem();
    return Number(value).toLocaleString(numericSystem);
};

export const commaSeparatedAndRoundedToTwoDigits = (value, digits = getRoundingPlaces()) => {
    const valueRounded = valueToFixedTwoDigits(roundedValue(value, digits), digits);
    return currencyFormatter(valueRounded);
};

export const isObjectEmptyOrValuesEmpty = (object) => {
    const valid = isObjectValidAndNotEmpty(object);
    if (valid) {
        const values = Object.values(object);
        let emptyValuesCount = 0;
        if (isArrayValidAndNotEmpty(values)) {
            for (let i = 0; i < values.length; i += 1) {
                if (!(values[i] && values[i])) {
                    emptyValuesCount += 1;
                }
            }
        }
        return values.length !== emptyValuesCount;
    }
    return false;
};

export const filterListWithProperties = (listOfData, propertiesToSearch, searchString) => {
    if (!searchString || !isArrayValidAndNotEmpty(propertiesToSearch)) {
        return listOfData || [];
    }
    if (isArrayValidAndNotEmpty(listOfData)) {
        const lowerCasedSearchString = searchString.toLowerCase();
        return listOfData.filter((aPatient) => {
            for (let i = 0; i < propertiesToSearch.length; i += 1) {
                const propertyToSearch = propertiesToSearch[i];
                const valueForProperty = getStringFromObject(propertyToSearch, aPatient) || '';
                if (valueForProperty.toLowerCase().includes(lowerCasedSearchString)) {
                    return true;
                }
            }
            return false;
        });
    }
    return [];
};

export const getObjectFromArrayWithValue = (keyName, value, myArray) => {
    let tmp = {};
    for (let i = 0; i < myArray.length; i += 1) {
        if (getStringFromObject(keyName, myArray[i]) === value) {
            tmp = myArray[i];
        }
    }
    return tmp;
};

export const capitalizeFirstLetter = (s) => {
    if (s) {
        return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
    }
    return s;
};

export const getTimeFromDate = date => `${date.getHours()}:${date.getMinutes()}`;

export const roundValue = (value, decimals) => Number(value.toFixed(decimals));

export const getDateInDDMMYYYYFormatWithZero = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;

    const yyyy = date.getFullYear();
    if (dd < 10) {
        dd = `0${dd}`;
    }
    if (mm < 10) {
        mm = `0${mm}`;
    }
    return `${dd}/${mm}/${yyyy}`;
};

export const getStringFromDateForStart = date => `${getDateInDDMMYYYYFormatWithZero(date)} 00:00`;

export const getStringFromDateForEnd = date => `${getDateInDDMMYYYYFormatWithZero(date)} 23:59`;

export const getNumberFromString = (data) => {
    let result = Number(data);
    if (Number.isNaN(result)) {
        result = 0;
    }
    return result;
};

export const getFirstLettersOfWordsInString = (text) => {
    let firstLettersOfWords = '';
    if (text) {
        const split = text.split(' ');
        split.map((aWord) => {
            firstLettersOfWords = `${firstLettersOfWords}${getStringFromObject([0], aWord)}`;
            return null;
        });
    }
    return firstLettersOfWords;
};

export const getLoadingSkeletonsForTable = (numberOfRow, numberOfColumns, uniqueName = uuidv4()) => {
    let loadingSkeleton = [];
    console.log('asd-0asuid[0-spkasd', numberOfRow, numberOfColumns, uniqueName);
    for (let i = 0; i < numberOfRow; i += 1) {
        let columnsForRow = [];
        for (let j = 0; j < numberOfColumns; j += 1) {
            columnsForRow = columnsForRow.concat(
                <TableCell
                    key={`${uniqueName}loading-col-${i}-${j}`}
                    component="th"
                    scope="row"
                >
                    <Skeleton />
                </TableCell>,
            );
        }
        loadingSkeleton = loadingSkeleton.concat(
            <TableRow key={`${uniqueName}loading-row-${i}`}>
                {columnsForRow}
            </TableRow>,
        );
    }
    console.log('asd-0asuid[0-spkasd', loadingSkeleton);
    return loadingSkeleton;
};

export const getRequiredFieldLabel = label => (
    <div>
        {label}
        <span style={{ color: 'red' }}>&nbsp;*</span>
    </div>
);

export const isEnterKeyPressed = e => (
    (e.keyCode === 13 || e.which === 13) && e.shiftKey === false
);

export const isGroupSeparatorPressed = e => (
    (e.keyCode === 221 || e.which === 221) && e.shiftKey === false && e.ctrlKey === true
);

export const minIndent = (str) => {
    const match = str.match(/^[ \t]*(?=\S)/gm);
    if (!match) {
        return 0;
    }
    // eslint-disable-next-line
    return Math.min.apply(Math, match.map(x => x.length));
};

export const stripIdent = (string) => {
    const indent = minIndent(string);

    if (indent === 0) {
        return string;
    }

    const regex = new RegExp(`^[ \\t]{${indent}}`, 'gm');

    return string.replace(regex, '');
};

export const findDefinedArrayElements = (array) => {
    let arrayOfDefinedElements = [];
    array.map((anElement) => {
        if (anElement) {
            arrayOfDefinedElements = arrayOfDefinedElements.concat(anElement);
        }
        return null;
    });
    return arrayOfDefinedElements;
};

const getDateObject = (render, num) => {
    let dateToFormat = null;
    const dateToRender = render(num);
    console.log('asdiad=iad0-ad', dateToRender, num);
    // eslint-disable-next-line no-restricted-globals
    if (dateToRender) {
        // eslint-disable-next-line
        if (isNaN(dateToRender)) {
            dateToFormat = new Date(dateToRender);
        } else {
            dateToFormat = new Date(Number(dateToRender));
        }
    }
    return dateToFormat;
};

export const parseQueryParams = (locationSearchString) => {
    let match;
    const pl = /\+/g; // Regex for replacing addition symbol with a space
    const search = /([^&=]+)=?([^&]*)/g;
    const decode = s => decodeURIComponent(s.replace(pl, ' '));
    const queryString = locationSearchString || window.location.search.substring(1);

    const urlParams = {};
    while (match = search.exec(queryString)) { // eslint-disable-line no-cond-assign
        urlParams[decode(match[1])] = decode(match[2]);
    }
    return urlParams;
};

export const formatUrl = (url, options, useQueryParams) => {
    const pattern = /{{([^}]*)}}/g;
    const matches = url.match(pattern);
    let replacedString = url;
    const checkQueryParams = useQueryParams || false;
    const queryParameters = parseQueryParams();
    if (matches) {
        matches.forEach((el) => {
            const key = el.replace('{{', '').replace('}}', '');
            let value = getStringFromObject(key, options);
            if (!value && (checkQueryParams === true)) {
                value = queryParameters[key] || null;
            }
            replacedString = replacedString.replace(el, value);
        });
    }
    return replacedString.trim();
};

export const getUrlWithApiParams = (url, params) => {
    if (url && isObjectValidAndNotEmpty(params)) {
        const names = Object.keys(params);
        const query = names.map((name) => {
            const value = getStringFromObject(name, params);
            if (Array.isArray(value)) {
                return `${name}=${value.map(v => v).join(',')}`;
            }
            return `${name}=${value}`;
        }).join('&');
        return `${url}?${query}`;
    }
    return url;
};

export const mustacheLambdas = {
    dateFormat: () => (num, render) => {
        const dateToFormat = getDateObject(render, num);
        return dateToFormat ? formatDateForDisplay(dateToFormat) : '';
    },
    dateTimeFormat: () => (num, render) => {
        const dateToFormat = getDateObject(render, num);
        console.log('as0dua-0uda0-ds', dateToFormat);
        return dateToFormat ? formatDateTimeForDisplay(dateToFormat) : '';
    },
    timeFormat: () => (num, render) => {
        const timeToFormat = getDateObject(render, num);
        console.log('as0dua-0uda0-ds', timeToFormat);
        return timeToFormat ? formatTimeForDisplay(timeToFormat) : '';
    },
    calculateAge: () => (num, render) => {
        const dateObject = getDateObject(render, num);
        return dateObject ? calculateAge(dateObject) : '';
    },
    extractKeyValue: () => (string, render) => {
        const keyValueString = render(string);
        const extractedString = keyValueString.substring(keyValueString.indexOf('{') + 1, keyValueString.indexOf('}'));
        const fieldsArray = extractedString.split(':');
        const key = fieldsArray[0];
        const value = fieldsArray[1];
        console.log('asdfasdfasdfasdfasfd', key, value);
        return value.substring(value.indexOf('"') + 1, value.indexOf('"'));
    },
    validity: () => (num, render) => {
        const date = getDateObject(render, num);
        if (isDateValid(date)) {
            let validity = (addOrSubtractYears(date, 1));
            validity = formatDateForDisplay(addOrSubtractDays(validity, -1));
            return validity;
        }
        return '';
    },
};

export const checkIfImageFile = (fileName) => {
    console.log('dcdfvjkfv', fileName);
    const imageTypes = ['jpg', 'jpeg', 'png'];
    for (let i = 0; i < imageTypes.length; i += 1) {
        const anImageType = imageTypes[i];
        if (fileName && fileName.toLowerCase().endsWith(anImageType.toLowerCase())) {
            return true;
        }
    }
    return false;
};

export const openConsultationApp = (appConfiguration, patientUuid) => {
    const {
        consultPatientPrivilege,
        consultationTabPrivilege,
        frontDeskPrivilege,
        nursePrivilege,
        consultationBaseLink: link,
    } = (appConfiguration || {});
    if (patientUuid) {
        let subLink = 'summary';
        if (
            checkIfPrivilegeExistsForUser(consultPatientPrivilege) ||
            checkIfPrivilegeExistsForUser(consultationTabPrivilege)
        ) {
            subLink = 'consultations';
        } else if (checkIfPrivilegeExistsForUser(nursePrivilege)) {
            subLink = 'nursing';
        } else if (checkIfPrivilegeExistsForUser(frontDeskPrivilege)) {
            subLink = 'documents';
        }
        window.open(`${link}/clinical/${patientUuid}/na/na/${subLink}`, patientUuid);
    }
};

export const openEncounter = (appConfiguration, {
    patient,
    episodeOfCareUuid,
    episodeProviderUuid,
    encounterUuid,
} = {}) => {
    const patientUuid = getStringFromObject('key', patient);
    console.log('asd0a9uasdas9d-22', patientUuid, episodeOfCareUuid, episodeProviderUuid);
    const consultationBaseLink = getStringFromObject('consultationBaseLink', appConfiguration);
    const url =
        // eslint-disable-next-line
        `${consultationBaseLink}/clinical/${patientUuid}/${episodeOfCareUuid || 'na'}/${episodeProviderUuid || 'na'}/consultations?encounterUuid=${encounterUuid}`;
    window.open(url, `${patientUuid}-${episodeOfCareUuid || 'na'}-${episodeProviderUuid || 'na'}`);
};

export const groupArrayOfObjectsByFieldValue = (arr, field) => {
    const grouped = {};
    if (isArrayValidAndNotEmpty(arr) && field) {
        arr.forEach((item) => {
            const prop = getStringFromObject(field, item);
            grouped[prop] = (grouped[prop] || []).concat(item);
        });
    }
    return grouped;
};

export const getTextFieldChangedValue = (eventOrValue) => {
    if (isObjectValidAndNotEmpty(eventOrValue) && isObjectValidAndNotEmpty(eventOrValue.target)) {
        return eventOrValue.target.value;
    }
    return eventOrValue;
};

export const getTextFieldValueByNativeEvent = (event) => {
    if (
        isObjectValidAndNotEmpty(event) &&
        isObjectValidAndNotEmpty(event.nativeEvent) &&
        isObjectValidAndNotEmpty(event.nativeEvent.target)
    ) {
        return event.nativeEvent.target.value;
    }
    return '';
};

export const isImageType = fileType => fileType && (
    [
        'jpg',
        'png',
        'svg',
        'JPG',
        'jpeg',
        'JPEG',
        'SVG',
        'PNG',
    ].includes(fileType)
);

export const findKeyForAValue = (theObject, theValue) => {
    if (isObjectValidAndNotEmpty(theObject)) {
        const theKeys = Object.keys(theObject);
        for (let i = 0; i < theKeys.length; i += 1) {
            if (deepEqual(theValue, theObject[theKeys[i]])) {
                return theKeys[i];
            }
        }
    }
    return null;
};

export const getPayerTypeColor = (payerType, colorSchemaForOPDQueue) => {
    console.log('sdfdfgh sdfdgweeeeeeeeeeeee1', colorSchemaForOPDQueue);
    let backgroundColor = '';
    switch (payerType) {
    case payerTypes.ASSOCIATION:
        backgroundColor = getStringFromObject(`${payerTypes.ASSOCIATION}.color`, colorSchemaForOPDQueue);
        break;
    case payerTypes.INSURANCE:
        backgroundColor = getStringFromObject(`${payerTypes.INSURANCE}.color`, colorSchemaForOPDQueue);
        break;
    case payerTypes.COMPANY:
        backgroundColor = getStringFromObject(`${payerTypes.COMPANY}.color`, colorSchemaForOPDQueue);
        break;
    case payerTypes.CORPORATE:
        backgroundColor = getStringFromObject(`${payerTypes.CORPORATE}.color`, colorSchemaForOPDQueue);
        break;
    case payerTypes.CASH:
        backgroundColor = getStringFromObject(`${payerTypes.CASH}.color`, colorSchemaForOPDQueue);
        break;
    default:
        backgroundColor = 'unset';
    }
    return backgroundColor;
};

export const getPayerTypeColorForInsurance = (payerType) => {
    let backgroundColor = '';
    console.log('sdfdfgh sdfdgweeeeeeeeeeeee2');
    switch (payerType) {
    // case payerTypes.ASSOCIATION:
    //     backgroundColor = '#FBE9E7';
    //     break;
    case payerTypes.CASH:
        backgroundColor = '#E1F5FE';
        break;
    // case payerTypes.CASH:
    //     backgroundColor = '#DCEDC8';
    //     break;
    default:
        backgroundColor = 'unset';
    }
    return backgroundColor;
};

export const isSlotAvailable = (slot, lateAppointmentCheckInGraceTime) => {
    if (isObjectValidAndNotEmpty(slot)) {
        const now = new Date();
        const maxAllowedTime = addOrSubtractMinutes(now, -lateAppointmentCheckInGraceTime);
        const slotTime = new Date(slot.slotDateTime);
        return !isDateBefore(slotTime, maxAllowedTime);
    }
    return false;
};

// not null safe
export const stringEqualsIgnoreCase = (str1 = '', str2 = '') => str1.toLowerCase() === str2.toLowerCase();

export const getSeekParamsAndDirection = (
    newPage,
    currentPage,
    forwardSeekMap,
    backwardSeekMap,
) => {
    let seekParams = null;
    let seekDirection = 'FORWARD';
    if (!newPage) {
        seekDirection = 'FORWARD';
        seekParams = null;
    } else if (newPage > currentPage) {
        // forward seek map
        seekParams = forwardSeekMap;
    } else if (newPage < currentPage) {
        // backward seek map
        seekParams = backwardSeekMap;
        seekDirection = 'BACKWARD';
    } else if (newPage === currentPage) {
        // current page seek map
        seekParams = backwardSeekMap;
        seekDirection = 'FORWARD';
    }
    return {
        seekParams,
        seekDirection,
    };
};

export const ORDER_TYPE_DATE_FILTERS = {
    TODAY: 'Today',
    YESTERDAY: 'Yesterday',
    LAST_5_DAYS: 'Past 5 Days',
    LAST_10_DAYS: 'Past 10 Days',
    LAST_30_DAYS: 'Past 30 Days',
    LAST_60_DAYS: 'Past 60 Days',
    LAST_90_DAYS: 'Past 90 Days',
    LAST_180_DAYS: 'Past 180 Days',
};

export const MY_PATIENTS_DATE_FILTERS = {
    ...ORDER_TYPE_DATE_FILTERS,
    LAST_1_YEAR: 'Past Year',
    LAST_2_YEAR: 'Past 2 Years',
};

export const getStartAndEndDateFromOrderTypeDateFilter = (filter) => {
    const now = new Date();
    let startDate = null;
    let endDate = null;
    switch (filter) {
    case ORDER_TYPE_DATE_FILTERS.YESTERDAY:
        startDate = startOfDay(addOrSubtractDays(now, -1));
        endDate = endOfDay(startDate);
        break;
    case ORDER_TYPE_DATE_FILTERS.LAST_5_DAYS:
        startDate = startOfDay(addOrSubtractDays(now, -5));
        endDate = endOfDay(now);
        break;
    case ORDER_TYPE_DATE_FILTERS.LAST_10_DAYS:
        startDate = startOfDay(addOrSubtractDays(now, -10));
        endDate = endOfDay(now);
        break;
    case ORDER_TYPE_DATE_FILTERS.TODAY:
        startDate = startOfDay(now);
        endDate = endOfDay(now);
        break;
    case ORDER_TYPE_DATE_FILTERS.LAST_30_DAYS:
        startDate = startOfDay(addOrSubtractDays(now, -30));
        endDate = endOfDay(now);
        break;
    case ORDER_TYPE_DATE_FILTERS.LAST_60_DAYS:
        startDate = startOfDay(addOrSubtractDays(now, -60));
        endDate = endOfDay(now);
        break;
    case ORDER_TYPE_DATE_FILTERS.LAST_90_DAYS:
        startDate = startOfDay(addOrSubtractDays(now, -90));
        endDate = endOfDay(now);
        break;
    case ORDER_TYPE_DATE_FILTERS.LAST_180_DAYS:
        startDate = startOfDay(addOrSubtractDays(now, -180));
        endDate = endOfDay(now);
        break;
    case MY_PATIENTS_DATE_FILTERS.LAST_1_YEAR:
        startDate = startOfDay(addOrSubtractYears(now, -1));
        endDate = endOfDay(now);
        break;
    case MY_PATIENTS_DATE_FILTERS.LAST_2_YEAR:
        startDate = startOfDay(addOrSubtractYears(now, -2));
        endDate = endOfDay(now);
        break;
    default:
        startDate = null;
        endDate = null;
    }
    return { startDate, endDate };
};

export const reprocessJson = (appConfiguration, jsonToProcess) => {
    let processedJson = cloneDeep(jsonToProcess);
    try {
        if (
            (isObjectValidAndNotEmpty(jsonToProcess) && jsonToProcess.processJson) ||
            isArrayValidAndNotEmpty(jsonToProcess)
        ) {
            processedJson = JSON.stringify(processedJson);
            console.log('asduadadu-adas', processedJson);
            processedJson = replaceValuesInString(processedJson, appConfiguration, 'tilda');
            processedJson = JSON.parse(processedJson);
        }
    } catch (e) {
        console.error('error occured while processing json');
    }
    return processedJson;
};

const getValueToDisplay = (type, value) => {
    switch (type) {
    case 'date':
        return value ? formatDateTimeForDisplay(new Date(value)) : '';
    case 'onlyDate':
        return value ? formatDateForDisplay(new Date(value)) : '';
    case 'price':
        return roundedValueFixedToTwoDigits(NumberOf(value));
    case 'boolean':
        return getStringForBoolean(value);
    default:
        return value;
    }
};

export const generateDataDisplays = (detailsToDisplay, dataObject) => (
    detailsToDisplay.map(aDataFieldToDisplay => (
        <Grid
            key={aDataFieldToDisplay.name}
            item
            {...aDataFieldToDisplay.grid}
        >
            <OutlinedTextField
                disabled
                label={aDataFieldToDisplay.label}
                value={
                    getValueToDisplay(
                        aDataFieldToDisplay.type,
                        getStringFromObject(aDataFieldToDisplay.find, dataObject),
                    )
                }
            />
        </Grid>
    ))
);

export const isTrueValue = value => value === true || value === 'true';
export const isJsonString = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const getRoundOffValue = (value) => {
    const roundingValue = getBillRoundingPlaces();
    console.log('as0d9uad0s', roundingValue);
    if (roundingValue > 0) {
        const halfRoundOfBy = roundingValue / 2.0;
        const remainder = value % roundingValue;
        return NumberOf(
            roundedValueFixedToTwoDigits(remainder < halfRoundOfBy ? -remainder : roundingValue - remainder),
        );
    }
    return 0;
};

export const getRoundOffValueWith = (value, roundingValue) => {
    if (roundingValue > 0) {
        const halfRoundOfBy = roundingValue / 2.0;
        const remainder = value % roundingValue;
        return NumberOf(
            roundedValueFixedToTwoDigits(remainder < halfRoundOfBy ? -remainder : roundingValue - remainder),
        );
    }
    return 0;
};

export const isPositiveNumber = num => NumberOf(num) >= 0;

export const unsubscribeToChannel = (subscription) => {
    if (subscription && isValidFunction(subscription.unsubscribe)) {
        subscription.unsubscribe();
    }
};

/**
 @deprecated use V2 instead
 */
export const copyToClipboard = (text) => {
    const dummy = document.createElement('textarea');
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    // Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
};

export const copyToClipboardV2 = async (text) => {
    try {
        await navigator.clipboard.writeText(text);
    } catch (error) {
        console.error(error);
    }
};

export const getAllowedDefaultAccountTypes = (cashType, allowedAccountTypes) => {
    let accountTypes = [];
    const defaultAccountTypes = {
        expenseCreditAccountTypes: ['liquidity'],
        prepaidExpenseCreditAccountTypes: ['liquidity'],
        prepaidExpenseExpenseAccountTypes: ['expense'],
        pettyCashFromAccountTypes: ['liquidity'],
        salaryAdvanceFromAccountTypes: ['liquidity'],
        payOrReceiveCreditAccountTypes: ['liquidity'],
        payOrReceivePartnerAccountTypes: ['payable', 'receivable', 'expense', 'income', 'equity'],
        creditOrDebitMemoAccountTypes: ['expense', 'contra income'],
        paymentVoucherAccountTypes: ['liquidity'],
    };
    switch (cashType) {
    case 'expenseCreditAccountTypes':
        accountTypes = getStringFromObject('expenseCreditAccountTypes', allowedAccountTypes) || defaultAccountTypes.expenseCreditAccountTypes;
        break;
    case 'prepaidExpenseCreditAccountTypes':
        accountTypes = getStringFromObject('prepaidExpenseCreditAccountTypes', allowedAccountTypes) || defaultAccountTypes.prepaidExpenseCreditAccountTypes;
        break;
    case 'prepaidExpenseExpenseAccountTypes':
        accountTypes = getStringFromObject('prepaidExpenseExpenseAccountTypes', allowedAccountTypes) || defaultAccountTypes.prepaidExpenseExpenseAccountTypes;
        break;
    case 'pettyCashFromAccountTypes':
        accountTypes = getStringFromObject('pettyCashFromAccountTypes', allowedAccountTypes) || defaultAccountTypes.pettyCashFromAccountTypes;
        break;
    case 'salaryAdvanceFromAccountTypes':
        accountTypes = getStringFromObject('salaryAdvanceFromAccountTypes', allowedAccountTypes) || defaultAccountTypes.salaryAdvanceFromAccountTypes;
        break;
    case 'payOrReceiveCreditAccountTypes':
        accountTypes = getStringFromObject('payOrReceiveCreditAccountTypes', allowedAccountTypes) || defaultAccountTypes.payOrReceiveCreditAccountTypes;
        break;
    case 'payOrReceivePartnerAccountTypes':
        accountTypes = getStringFromObject('payOrReceivePartnerAccountTypes', allowedAccountTypes) || defaultAccountTypes.payOrReceivePartnerAccountTypes;
        break;
    case 'creditOrDebitMemoAccountTypes':
        accountTypes = getStringFromObject('creditOrDebitMemoAccountTypes', allowedAccountTypes) || defaultAccountTypes.creditOrDebitMemoAccountTypes;
        break;
    case 'paymentVoucherAccountTypes':
        accountTypes = getStringFromObject('paymentVoucherAccountTypes', allowedAccountTypes) || defaultAccountTypes.paymentVoucherAccountTypes;
        break;
    default:
        accountTypes = [];
    }

    return encodeURIComponent(accountTypes.join(','));
};
export const sortArrayOfObjectsByStringValue = (arr, field) => {
    if (!isArrayValidAndNotEmpty(arr)) {
        return arr;
    }
    return arr.sort((a, b) => {
        let aName = getStringFromObject(field, a);
        let bName = getStringFromObject(field, b);
        if (typeof aName === 'string' && typeof bName === 'string') {
            aName = aName.toLowerCase();
            bName = bName.toLowerCase();
            if (aName < bName) {
                return -1;
            } else if (aName > bName) {
                return 1;
            }
        }
        return 0;
    });
};

export const hexToRgb = hex =>
    hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        // eslint-disable-next-line
        (m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16));

export const promiseResolver = async (promise, dispatcher) => {
    try {
        const response = await promise;
        return [response.data, null];
    } catch (e) {
        apiCatchBlockFunction(e, dispatcher);
        return [null, e];
    }
};

export const getScanUploadUrl = (scanUploadApi = '/nuacare-core/web/nuacare/v1/file/uploadSingle/hr') => (
    `${window.location.protocol}//${window.location.host}${scanUploadApi}`
);

// eslint-disable-next-line valid-jsdoc
/**
 * @deprecated
 * Drug name field will be sent from backend
 * */
export const getGenericDrugName = (genericDrugAttribute) => {
    if (isObjectValidAndNotEmpty(genericDrugAttribute)) {
        return `${genericDrugAttribute.code || ''} - ${genericDrugAttribute.ingredients || ''} ${genericDrugAttribute.strength || ''}${genericDrugAttribute.unit || ''} ${genericDrugAttribute.dosage || ''}`;
    }
    return '';
};

export const toTitleCase = str => str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
);

export const toCamelCase = str => str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

// can be used to remove choices from actions based on conditon,
// if conditon matches > choice will be removed
export const getModifiedSchemaWithFilteredActions = (schema, configValues, conditions) => {
    if (!isArrayValidAndNotEmpty(conditions)) {
        return schema;
    }
    const tableCells = getStringFromObject('tableCells', schema) || [];
    const actionsObjectIndex = tableCells.findIndex(e => e.name === 'rowAction');
    const actionObject = getStringFromObject(`${actionsObjectIndex}`, tableCells) || {};
    if (!isObjectValidAndNotEmpty(actionObject)) {
        return schema;
    }

    const choices = actionObject.choices || [];
    const newChoices = [];

    choices.forEach((choice) => {
        let allowedFlag = true;
        for (let index = 0; index < conditions.length; index += 1) {
            const condition = conditions[index];
            const conditionKey = getStringFromObject('key', condition);
            const conditionValueFromConfig = getStringFromObject(conditionKey, configValues);
            const conditionValuetoBeChecked = getStringFromObject('value', condition);
            const actionKey = getStringFromObject('actionKey', condition);
            if (choice.key === actionKey && conditionValueFromConfig === conditionValuetoBeChecked) {
                allowedFlag = false;
                break;
            }
        }
        if (allowedFlag) {
            newChoices.push(choice);
        }
    });

    setStringPropertyToObject(`tableCells[${actionsObjectIndex}].choices`, schema, newChoices);
    return schema;
};

export const isKeyOfKeycodePressed = (e, keycode) => (e.key === keycode);
export const getUniqueFileTypes = (fileTypes) => {
    let uniqueTypes = '';
    fileTypes.map((format) => {
        const formatType = format.toLowerCase();
        if (!uniqueTypes.includes(formatType)) {
            uniqueTypes = `${uniqueTypes}, ${formatType}`;
        }
        return null;
    });
    return uniqueTypes.substr(2, uniqueTypes.length);
};
export const sortData = (data, sortCol, sortAsc) => {
    const sortedArr = [...data];
    sortedArr.sort((a, b) => {
        if (sortAsc) {
            return a[sortCol] < b[sortCol] ? -1 : 1;
        }
        return a[sortCol] < b[sortCol] ? 1 : -1;
    });
    return sortedArr;
};
export const getProfileImageUrl = (patient) => {
    const birthDate = patient.dob || patient.birthDate;
    const age = calculateAge(new Date(birthDate));
    console.log('sdccfvfdslvkmdflkiuewoiu', patient, age);
    let url = `${import.meta.env.BASE_URL}/profileImage/adultMale.svg`;
    console.log('dsvkcnvkdfnvdk', age, url);
    if (patient.gender === 'F' || patient.gender === 'f' || (patient.gender || '').toLowerCase() === 'female') {
        if (age <= 12) {
            url = `${import.meta.env.BASE_URL}/profileImage/childFemale.svg`;
        } else {
            url = `${import.meta.env.BASE_URL}/profileImage/adultFemale.svg`;
        }
    } else if (patient.gender === 'M' || patient.gender === 'm' || (patient.gender || '').toLowerCase() === 'male') {
        if (age <= 12) {
            url = `${import.meta.env.BASE_URL}/profileImage/childMale.svg`;
        }
    }
    return url;
};
export const getJsonPath = (path, configUrl = import.meta.env.BASE_URL) => {
    const appVersionNumber = getAppVersion() || '';
    let jsonPath = path || '';
    if (!jsonPath.startsWith('/')) {
        jsonPath = '/'.concat(jsonPath);
    }
    return `${configUrl}${jsonPath}?ver=${appVersionNumber}`;
};

export const isMobileDevice = () => {
    if (window.matchMedia('(max-width: 1023px)').matches) {
        return true;
    } else {
        return false;
    }
}

export const getQueueAuthHeaderConfigForRequest = (queueAuth) => {
    const authHeaderConfig = { Authorization: `Basic ${queueAuth}` };
    return authHeaderConfig;
};
