/*eslint-disable*/
import React from 'react';
import {
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
} from '@material-ui/core/colors';
import {
    leavePrivilege,
    opdDoctorPrivilege,
    organisationAdmin,
    searchActivePatientPrivilege,
    searchAllPatientPrivilege
} from './privilegeConstants';
import cloneDeep from 'clone-deep';
import { isObjectValidAndNotEmpty } from './nullCheckUtils';

export const PAGE = 0;
export const SIZE = 10;
export const SORT_ORDER = 'asc';
export const SORT_ORDER_DESC = 'desc';
export const SORT_COL = 'id';
export const ROWS_PER_PAGE = [10, 20, 50];

export const claimStateAction = {
    SUBMIT: 'submit',
    SAVE: 'save',
    PAID: 'paid',
};

export const GLOBAL_PROPERTIES = {
    QUEUE_SLACK_DURATION: 'queue.slack.duration',
    ALLOW_CONTRACT_EDIT_FOR_PAST_DATES: 'allow_contract_edit_for_past_date',
    DISCOUNT_CONFIG: 'registration.discount.config',
    PRIVILEGE_DISCOUNT_CONFIG: 'registration.privilege.discount.config',
    STOCK_MISC_OPS_HELPER: 'stock.misc.ops.helpers',
    EMR_STUDY_DOC_REMOVE: 'emr.order.study.document.remove',
    PERFORM_STOCK_CHECKS_NURSE_QUEUE: 'queue.perform.stock.checks',
    SECONDARY_IDENTIFIER_LABEL: 'secondary.identifier.label',
    EP_STRICT_CHECK: 'episodeStrictCheck',
    ROUNDING_PLACES: 'ROUNDING_PLACES',
    BILL_AMOUNT_ROUND: 'nuacare.bill.amount.round',
    CURRENCY: 'currency',
    SHOW_MY_PAYSLIP_IN_DASHBOARD: 'nuacare.show.my.payslip',
    IGNORE_BATCH_PRICE: 'nuacare.ignore.batch.price',
    MULTIPLE_TOKEN_TYPE_SUPPORTED: 'nuacare_multiple_token_type_supported',
    PACKAGE_SPECIAL_CASE_CONFIG: 'nuacare_package_special_cases',
    TAX_EXEMPTION_ATTRIBUTES: 'tax_exemption_attributes',
    NON_EDITABLE_NON_DRUGS: 'nuacare_non_editable_non_drugs',
    INSURANCE_DIRECT_BILLABLE_CATEGORY: 'nuacare.insurance.direct.billable.categories',
    ENCOUNTER_OT_STATUS_BIT_POS_MAP: 'ENCOUNTER_OT_STATUS_BIT_POS_MAP',
    INS_REG_LOAD_SCHEME: 'nuacare_insurance_reg_load_scheme',
    READ_ONLY_PRODUCT_CATEGORIES: 'read_only_categories',
    SHOW_CAF_LINES_IN_CLAIM_DETAILS: 'nuacare_show_caf_lines_in_claim',
    ENABLE_GENERIC_DRUG_PRESCRIPTION: 'enable.generic.drug.prescription',
    ENABLE_PRINTING_PRODUCT_DEPENDENT_DOCUMENTS: 'enable.printing.product.dependent.documents',
    ENABLE_PRINTING_PAYER_INVOICE_IN_REGISTRATION: 'enable.printing.payer.invoice.in.registration',
    ENABLE_DAILY_COPAY_LIMIT: 'enable.daily.copay.limit',
    NUACARE_MAX_PROVIDER_FAVORITES: 'nuacare_max_provider_favorites',
    ENABLE_PRINT_SIDEMENU_REGISTRATION: 'enable.printsidemenu.registration',
    REMOVE_REPORT_ON_EDIT_STUDY: 'remove.report.on.edit.study',
    ENABLE_COST_CHECK: 'enable.cost.check',
    COST_CHECK_PAYERS: 'cost.check.payers',
    DAILY_MAX_COPAY_MANDATORY: 'daily.max.copay.mandatory',
    MAX_COPAY_MANDATORY: 'max.copay.mandatory',
    ENABLE_A4_INVOICE_PRINT: 'enable.A4.invoice.print',
    PRESCRIPTION_TYPES_FOR_PAYER_TYPES: 'prescription.types.for.payer.types',
    NUACARE_NON_EDITABLE_NON_DRUGS: "nuacare_non_editable_non_drugs",
    DISABLE_ID_CARD_MANDATORY_FOR_INSURANCE_PATIENT: 'disable.id.card.mandatory.for.insurance.patient',
    NUMBER_OF_DAYS_FOR_PREVIOUS_PURCHASE_INVOICE_LINES_FETCH: 'number.of.days.for.previous.purchase.invoice.lines.fetch',
    GENERAL_PAYABLE_AND_GENERAL_RECEIVABLE_ACCOUNTS: 'general.payable.and.general.receivable.accounts',
    INVOICE_LINE_EXTRA_DETAIL_PRODUCT_CATEGORIES: 'invoice.line.extra.detail.product.categories',
    QUICK_REGISTER_PATIENT_FORM_SECONDARY_IDENTIFIER_VALIDATIONS: 'quick.register.patient.form.secondary.identifier.validations',
    ENABLE_SEND_SMS_FOR_APPOINTMENT_CREATION: 'enable.send.sms.for.appointment.creation',
    ENABLE_SEND_SMS_FOR_APPOINTMENT_LIST: 'enable.send.sms.for.appointment.list',
    ENABLE_SEND_SMS_FOR_RECALL_LIST: 'enable.send.sms.for.recall.list',
    ALLOW_CHANGE_PAYER_WITHOUT_METADATA_UPDATION: "allow.change.payer.without.metadata.updation",
    ANALYTICS_CURRENCY_NUMERIC_SYSTEM: "analytics.currency.numeric.system",
    QUEUE_REGISTRATION_ENABLED: 'queue.registration.enabled',
    QUEUE_APPROVAL_ENABLED: 'queue.approval.enabled',
    STORE_STUDY_DOCUMENTS_IN_LOCAL_SERVER: "store.dicom.documents.in.local.server",
    STUDY_DOCUMENT_LOCAL_SERVER_BASE_URL: "study.document.local.server.base.url",
    DISABLE_INSURANCE_INVOICE_EDIT_V2: "disable.insurance.invoice.edit.v2",
    USE_CHANGE_PAYER_SCREEN_PAYER_TYPES: "use.change.payer.screen.payer.types",
    USE_REGISTRATION_V3: 'use.registration.v3',
    SHOW_TAX_EXEMPT_FOR_CREDIT_BILL: 'show.tax.exempt.for.credit.bill',
    NPHIES_ENABLED:'nphies.enabled',
    IMPLEMENTATION_LOCATION:'implementation.location',
    DISABLE_APPROVAL_CONTAINER_V2:'disable.approval.container.v2',
    START_DATE_FOR_HR_PAYROLL_PERIOD:  'start.date.for.hr.payroll.period',
    FORMULARY_ITEM_TYPE_OPTIONS:  'formulary.item.type.options',
    EXPIRED_PRODUCT_INTERNAL_MOVE_LOCATIONS: 'expired.product.internal.move.locations',
    DUTY_ROSTER_PRINT_DATA: 'duty.roster.print.data',
    COMPANY_EXPIRY_PRIVILEGE_CARDS:'enable.company.and.expiry.in.privilege.discount',
    CREDIT_MEMO_JASPER_PRINT:'print.credit.memo.jasper',
    HIDE_OTHER_PAYMENT_MODE_IN_MULTIMODE_PAYMENT:'hide.other.payment.mode.in.multimode.payment',
    POST_TYPE_PACS_CREDENTIALS_AND_ORDER_TYPES: 'post.type.pacs.credentials.and.order.types',
    SEND_TO_BILLING_DISABLED_PRODUCT_ID: 'send.to.billing.disabled.product.id',
    ENABLE_GROSS_AMOUNT_FOR_MAX_AUTH_LIMIT_CHECK: 'enable.gross.amount.for.max.auth.limit.check',
    DISABLE_NPHIES_CODE_VALIDATION: 'disable.nphies.code.validation',
    DISABLE_SFDA_CODE_VALIDATION: 'disable.sfda.code.validation',
    DISABLE_DATE_RANGE_LIMIT: 'disable.date.range.limit',
    DISABLE_PROVIDERS_WITH_MINIMAL_DEPENDENT_VALUES: 'disable.providers.with.minimal.dependent.values',
    SALES_DISCOUNT_CONFIG:'sales.discount.config',
    ENABLE_PREDETERMINATION: 'enable.predetermination',
    ENABLE_PBM: 'enable.pbm',
    CDS_CUSTOM_KEY: 'cds.custom.key',
    ENABLE_AUTO_ASSIGN_FOR_LAB_QUEUE: 'enable.auto.assign.for.lab.queue',
    ENABLE_USE_BATCH_PRICE: 'enable.use.batch.price',
    ENABLE_SPLIT_INVOICES_FOR_REPORTABLE_ITEMS:'enable.split.invoices.for.reportable.items',
    USER_ALERT_MESSAGE_CONTENT: 'user.alert.message.content',
    ALLOW_SERVICE_INVOICE_IN_PHARMACY_CLAIM: 'allow.service.invoice.in.pharmacy.claim',
    ENABLE_USER_ALERT_MESSAGE: 'enable.user.alert.message',
    ENABLE_SEND_TO_NPHIES_V2: 'enable.send.to.nphies.v2',
    ENABLE_EMP_ACCOUNTS: 'enable.emp.accounts',
};

export const rootPage = '/';
export const opd = '/opd';
export const ris = '/ris';
export const ordersQueue = '/ordersQueue';
export const inbox = '/inbox';
export const opdQueue = '/opd/queue';
export const missedList = '/opd/missed';
export const clinicalInbox = '/opd/clinicalInbox';
export const myPatients = '/opd/my-patients';
export const opdAppointments = '/opd/appointments';
export const ipd = '/ipd';
export const nurseQueue = '/nurse-queue';
export const technicianQueue = '/technician-queue';
export const proceduresQueue = '/procedures-queue';
export const appointments = '/appointments';
export const registration = '/registration';
export const registrationV2 = '/registration-V2';
export const registrationV3 = '/registration-V3';
export const patientDocumentsPage = '/patient-documents';
export const billedMedicalPackages = '/billed-medical-packages'
export const workBoxMailPage = '/work-box';
export const appointmentsSetting = '/appointments/setting';
export const appointmentsSettingDepartments = '/appointments/setting/departments';
export const appointmentsSettingServices = '/appointments/setting/services';
export const appointmentsSettingTemplates = '/appointments/setting/templates';
export const appointmentsSettingDailySchedule = '/appointments/setting/dailySchedule';
export const appointmentsSettingQueueEdit = '/appointments/setting/queueEdit';
export const appointmentsList = '/appointments/list';
export const appointmentsListing = '/appointments/list/appointments-list';
export const appointmentsWaitingListing = '/appointments/list/waiting-list';
export const appointmentsRecallListing = '/appointments/list/recall-list';
export const dashboard = '/dashboard';
export const dashboardN = '/dashboard-n';
export const profile = '/profile';
export const organization = '/organization';
export const stockManagement = '/stock-management';

export const purchasePage = '/purchase';
export const supplierInvoices = '/purchase/supplier-invoices';
export const purchaseSuppliers = '/purchase/supplier';
export const purchasePayments = '/purchase/payments';
export const purchaseOrders = '/purchase/orders';
export const productDispatch = '/purchase/productDispatch';


export const stockManagementBatches = '/stock-management/batches';
export const stockManagementConsumables = '/stock-management/consumables';
export const stockManagementOpticalProducts = '/stock-management/optical-products';
export const stockManagementDrugs = '/stock-management/drugs';
export const stockManagementProductCategories = '/stock-management/product-categories';
export const stockManagementReorderRules = '/stock-management/reorder-rules';
export const purchaseOrder = '/purchase-order';
export const stockAdvancedSearch = '/advanceSearch';
export const organizationTree = '/organization/tree';
export const organizationList = '/organization/list';
export const payroll = '/payroll';
export const paySlipBatches = '/payroll/management/payslip-batches';
export const editPaymentAdvice = '/payroll/management/payment-advice/edit';
export const newPaySlipBatches = '/payroll/management/payslip-batches/new';
export const myPayslip = '/my-payslip';
export const editPaySlipBatches = '/payroll/management/payslip-batches/edit';
export const payslipInput = '/payroll/management/payslip-batches/payslip';
export const paymentAdvices = '/payroll/management/payment-advices';
export const payslips = '/payroll/management/payslips';
export const payrollReports = '/payroll/management/payroll-reports';
export const payrollHr = '/hrpayroll';
export const leaveBasePage = '/leaves';
export const leaves = '/leaves/list';
export const leavesBalances = '/leaves/balance';
export const eform = '/eform';
export const documents = '/documents';
export const documentsHr = '/hrDocuments';
export const holidays = '/holidays';
export const addNewHoliday = '/holidays/edit';
export const createNewHoliday = '/holidays/create';
export const viewHolidays = '/holidays/view';
export const dutyRoster = '/dutyRoster';
export const myCashClose = '/myCashClose';
export const assets = '/assets';
export const assetCategory = '/assets/asset-category';
export const assetManufacturer = '/assets/asset-manufacturer';
export const assetsCreate = '/assets/add';
export const assetsEdit = '/assets/edit';
export const assetsView = '/assets/view';
export const announcements = '/announcements';
export const announcementsCreate = '/announcements/create';
export const announcementsEdit = '/announcements/edit';
export const workflow = '/workflow';
export const patientSearch = '/patients';
export const patientActive = '/patients/active';
export const patientRecent = '/opd/recent';
export const openEncounters = '/opd/encounters';
export const patientAll = '/patients/all';
export const bookManagement = '/book-management';
export const bookManagementJournalEntries = '/book-management/journal-entries';
export const bookManagementAccount = '/book-management/chart-of-account';
export const bookManagementAging = '/book-management/aging';
export const bookManagementSearch = '/book-management/search';
export const bookManagementAnalyticalAccount = '/book-management/analytical-account';
export const bookManagementPayerCollection = '/book-management/payer-collection';
export const bookManagementBankReconciliation = '/book-management/bank-reconciliation';
export const bookManagementCardReconciliation = '/book-management/card-reconciliation';
export const bookManagementSupplierPurchaseBill = '/book-management/supplier/purchase-bill';
export const bookManagementPaymentVoucher = '/book-management/payment-voucher';
export const bookManagementDebitAndCreditMemo = '/book-management/debit-credit-memo';
export const reports = '/reports';
export const payors = '/payors';
export const settings = '/settings';
export const services = '/settings/services';
export const bookSettings = '/settings/books';
export const serviceCategory = '/settings/services/service-category';
export const booksSettings = '/settings/books/fiscal-years';
export const costAccountSettings = '/settings/cost-accounting';
export const costAccountSettingsDistributionPlan = '/settings/cost-accounting/distribution-plan';
export const costAccountSettingsAnalyticsDefault = '/settings/cost-accounting/analytics-default';
export const billableProductService = '/settings/services/products';
export const analyticalDistributionService = '/settings/services/analytical-distribution';
export const orderableProductService = '/settings/services/orderable-services';
export const limitedServicesSettings = '/settings/services/limited-services';
export const medicalPackages = '/settings/services/medical-packages';
export const packageListService = '/settings/services/packages';
export const generalSettingService = '/settings/services/general';
export const clinicSettingService = '/settings/services/clinic';
export const periodSettings = '/settings/books/periods';
export const periodicProcessingSettings = '/settings/books/periodic-processing';
export const cashCloseTypesSettings = '/settings/books/cash-close-types';
export const fiscalYearSettings = '/settings/books/fiscal-years';
export const analytics = '/analytics';
export const utilPage = '/util';
export const payorSettings = '/settings/payor';
export const payorList = '/settings/payor/list';
export const payorProductCategory = '/settings/payor/product-category';
export const sfdaGtin = '/settings/payor/sfda-gtin';
export const policyViewList = '/settings/payor/policy-list';
export const insuranceSchemeViewList = '/settings/payor/insurance-scheme-list';
export const payorNetwork = '/payor/network';
export const payorSubInsurance = '/payor/network/sub-insurance';
export const payorPolicy = '/payor/network/policy';
export const payerScheme = '/payor/network/scheme';
export const payorApproval = '/payors/approval';
export const payorInvoice = '/payors/invoice';
export const payorClaims = '/payors/claims';
export const payorClaimsDetails = '/payors/claims/details';
export const payorBundles = '/payors/bundles/';
export const payorBundlesDetails = '/payors/bundles/details';
export const payorBundlesDetailsBooks = '/book-management/payer/bundles/details';
export const insuranceRequests = '/payors/requests';
export const invoiceEditAndSavePage = '/invoice/:uuid';
export const attendanceList = '/attendance';
export const employeeAttendance = '/attendance/employee';
export const notificationSetting = '/settings/notification-setting';
export const smsSetting = '/settings/notification-setting/sms-setting';
export const autoTextSettings = '/settings/auto-text';
export const autoTextCategorySettings = '/settings/auto-text/categories';
export const labSettings = '/settings/lab';
export const labSettingPanels = '/settings/lab/panels';
export const labSettingDepartments = '/settings/lab/labDepartment';
export const labSettingSample = '/settings/lab/sample';
export const labSettingDictionary = '/settings/lab/dictLab';
export const labSettingTestMethods = '/settings/lab/testMethods';
export const labSettingUom = '/settings/lab/uom';
export const labSettingTemplate = '/settings/lab/template';
export const dentalSettings = '/settings/dental';
export const dentalTreatments = '/settings/dental/treatments';
export const dutyRosterSettings = '/settings/duty-roster';
export const dutyRosterShiftsSettings = '/settings/duty-roster/shifts';
export const miscSettings = '/settings/misc-settings';
export const userManagementSettings = '/settings/user-management-settings';
export const medicationSettings = '/settings/medication-settings';
export const medicationSettings2 = '/settings/medication-settings-2';
export const organisationSettings = '/settings/organisation-settings';
export const hrLeaveSettings = '/settings/hr-leave-settings';
export const attendanceSettings = '/settings/attendance-settings';
export const mrsLocationSettings = '/settings/misc-settings/mrsLocation';
export const stockLocationSettings = '/settings/misc-settings/stockLocation';
export const hrPayrollPeriodSettings = '/settings/misc-settings/hrPayrollPeriod';
export const saleShopSettings = '/settings/misc-settings/saleShop';
export const hrJobsSettings = '/settings/organisation-settings/hrJob';
export const addressDetailsSettings= '/settings/organisation-settings/addressDetails';
export const salaryStructureSettings = '/settings/misc-settings/salaryStructure';
export const salaryRuleSettings = '/settings/misc-settings/salaryRule';
export const ruleInputCodeSettings = '/settings/misc-settings/ruleInputCode';
export const globalPropertySettings = '/settings/misc-settings/globalProperty';
export const discountSettings = '/settings/misc-settings/discSettings';
export const privCardCompanySettings = '/settings/misc-settings/privCardCompany';
export const privilegeSettings = '/settings/user-management-settings/privilege';
export const roleSettings = '/settings/user-management-settings/role';
export const genericsSettings = '/settings/medication-settings/generics';
export const frequencySettings = '/settings/medication-settings/frequency';
export const drugClassSettings = '/settings/medication-settings/drugClass';
export const drugRouteSettings = '/settings/medication-settings/drugRoutes';
export const dosageFormSettings = '/settings/medication-settings-2/dosageForm';
export const dosageUnitSettings = '/settings/medication-settings-2/dosageUnit';
export const dosageInstructionSettings = '/settings/medication-settings-2/dosageInstructions';
export const allergyReactionSettings = '/settings/medication-settings-2/allergyReaction';
export const allergySeveritySettings = '/settings/medication-settings-2/allergySeverity';
export const hrDepartmentSettings = '/settings/organisation-settings/hrDepartment';
export const warningsSettings = '/settings/medication-settings-2/warnings';
export const extendedGenericAttribute = '/settings/medication-settings-2/extended-generic-attribute';
export const hrLeavePlanSettings = '/settings/hr-leave-settings/hrLeavePlan';
export const hrLeaveTypeSettings = '/settings/hr-leave-settings/hrLeaveType';
export const workShiftSettings = '/settings/attendance-settings/workShift';
export const workHourTemplateSettings = '/settings/attendance-settings/workHourTemplate';
export const contractTypeSettings = '/settings/attendance-settings/contractType';
export const bedManagementSettings = '/settings/bed-management-settings';
export const bedTypeSettings = '/settings/bed-management-settings/bedType';
export const bedLayoutSettings = '/settings/bed-management-settings/bedLayout';
export const accountTaxSettings = '/settings/books/tax';
export const defaultJournalSettings = '/settings/books/default-journal';
export const irSequenceSettings = '/settings/books/ir-sequence';
export const configurationSettings = '/settings/configuration-settings';
export const commonSettings = '/settings/configuration-settings/commonSettings';

export const mrsSettings = '/settings/mrs';
export const addDiagnosisSettings = '/settings/mrs/diagnosis';

export const bulkUploadSettingsPath = '/settings/bulk-upload'

export const risUrls = {
    home: '/ris/queue',
    orders: '/ris/orders',
    oldOrders: '/ris/oldOrders',
    orderDetail: '/ris/order/report',
    missedList: '/ris/missed',
    reports: '/ris/reports',
    reportTemplates: '/ris/templates',
};

export const ordersQueueUrls = {
    home: '/ordersQueue/queue',
    orders: '/ordersQueue/orders',
    oldOrders: '/ordersQueue/oldOrders',
    orderDetail: '/ordersQueue/order/report',
    missedList: '/ordersQueue/missed',
    reports: '/ordersQueue/reports',
    reportTemplates: '/ordersQueue/templates',
};

export const leftPaneToPageMap = {
    'opd': opdQueue,
    'ris': risUrls.home,
    'ordersQueue': ordersQueueUrls.home,
    'inbox': `${inbox}/requests`,
    'ipd': ipd,
    'dashboard': dashboard,
    'profile': profile,
    'organization': `${organization}/list`,
    'payroll': payroll,
    'payroll-management': paySlipBatches,
    'duty-roster': dutyRoster,
    'myCashClose':myCashClose,
    'leaves': leaves,
    'eform': eform,
    'documents': documents,
    'holidays': holidays,
    'dutyRoster': dutyRoster,
    'assets': assets,
    'announcements': announcements,
    'patient': patientSearch,
    'stock-management': stockManagement,
    'purchase': purchasePage,
    'nurse-queue': nurseQueue,
    'technician-queue': technicianQueue,
    'procedures-queue': proceduresQueue,
    'appointments': appointments,
    'registration': registration,
    'reports': reports,
    'book-management': bookManagement,
    'registration-v2': registrationV2,
    'registration-v3': registrationV3,
    'patient-documents': patientDocumentsPage,
    'billed-medical-packages': billedMedicalPackages,
    'payorSettings': payorList,
    'payor': payorApproval,
    'serviceSettings': serviceCategory,
    'booksSettings': booksSettings,
    'costAccountSettings': costAccountSettings,
    'periodicProcessingSettings': periodicProcessingSettings,
    'attendance': attendanceList,
    'notificationSettings': notificationSetting,
    'autoTextSettings': autoTextSettings,
    'autoTextCategorySettings': autoTextCategorySettings,
    'labSettings': labSettings,
    'dentalSettings': dentalSettings,
    'dutyRosterSettings': dutyRosterSettings,
    'miscSettings': mrsLocationSettings,
    'costAccountingSettings': costAccountSettings,
    'bedManagementSettings': bedTypeSettings,
    'userManagementSettings': roleSettings,
    'medicationSettings': drugClassSettings,
    'medicationSettings2': allergySeveritySettings,
    'organisationSettings': hrDepartmentSettings,
    'hrLeaveSettings': hrLeaveTypeSettings,
    'attendanceSettings': workShiftSettings,
    'util': utilPage,
    'mrs': mrsSettings,
    'configurationSettings': commonSettings,
    'bulkUploader': bulkUploadSettingsPath
};

export const analyticsLeftPaneToPageMap = {
    'clinicalSales': `${analytics}/clinicalSales`,
    'pharmacySales': `${analytics}/pharmacySales`,
    'outPatient': `${analytics}/outPatient`,
    'supplier': `${analytics}/supplier`,
    'laboratory': `${analytics}/laboratory`,
    'pharmacy': `${analytics}/pharmacy`,
    'inPatient': `${analytics}/inPatient`,
    'radiology': `${analytics}/radiology`,
    'doctors': `${analytics}/doctors`,
    'inventory': `${analytics}/inventory`,
    'patientSatisfaction': `${analytics}/patientSatisfaction`,
    'humanResources': `${analytics}/humanResources`,
    'payers': `${analytics}/payers`,
    'fixedAssets': `${analytics}/fixedAssets`,
    'costAccounts': `${analytics}/costAccounts`,
    'accounts': `${analytics}/accounts`,
    'dental': `${analytics}/dental`,
    'dailyCollections': `${analytics}/dailyCollections`,
};

export const hrPayrollLeftPaneToPageMap = {
    'payslip-batches': paySlipBatches,
    'payment-advices': paymentAdvices,
    'payslips': payslips,
    // 'payroll-reports': payrollReports,
};

export const doctorPaneToPageMap = {
    opdQueue,
    opdAppointments,
    patientRecent,
    openEncounters,
    missedList,
    clinicalInbox,
    myPatients,
};

export const appointmentSettingToPageMap = {
    'department': appointmentsSettingDepartments,
    'service': appointmentsSettingServices,
    'templates': appointmentsSettingTemplates,
    'dailySchedule': appointmentsSettingDailySchedule,
    'queueEdit': appointmentsSettingQueueEdit,
};

export const appointmentListingToPageMap = {
    'apppointmentsList': appointmentsListing,
    'recallList': appointmentsRecallListing,
    'waitingList': appointmentsWaitingListing,
    // 'tentativeList': appointmentsTentativeListing,
};

export const patientPaneToPageMap = {
    'recent': patientRecent,
    'active': patientActive,
    'all': patientAll,
};

// export const mapWorkflowTypeToConfig = {
//     'leave': 'employeeLeaveRequestProcess',
//     'travel': 'travelRequests',
//     'expense-approval': 'expenseApproval',
//     'employee-loan': 'employeeAdvanceLoanProcess',
//     'advance-salary': 'employeeAdvanceSalaryProcess',
//     'petty-cash': 'employeePettyCashProcess',
//     'resignation': 'employeeResignationProcess',
//     'overtime-claim': 'employeeOvertimeProcess',
//     'employee-exit': 'emoloyeeExitChecklistProcess',
//     'general-service': 'itHelpDeskRequestProcess',
//     'employee-onboard': 'employeeOnboardingProcess',
//     'vacation-leave': 'employeeVacationRequestProcess',
//     'mileage-reimbursement': 'employeeMileageReimbursement',
//     'transport-request': 'transportationRequestProcess',
//     'stationary-request': 'employeeStationaryRequestProcess',
//     'attendance-regularisation': 'employeeAttendanceRegularisationProcess',
//     'purchase-request': 'employeePurchaseRequestProcess',
//     'asset-order': 'assetWorkOrderRequestProcess',
//     'leave-cancel': 'employeeLeaveCancelRequestProcess',
//     'asset-request': 'employeeAssetRequestProcess',
// };

export const mapWorkflowUrlToTitle = {
    'leave': 'Leave Request',
    'travel': 'Travel Request',
    'expense-approval': 'Expense Approval',
    'employee-loan': 'Employee Loan',
    'advance-salary': 'Employee Advance Salary',
    'petty-cash': 'Petty Cash',
    'resignation': 'Employee Resignation',
    'overtime-claim': 'Overtime Claim',
    'employee-exit': 'Employee Exit',
    'general-service': 'IT Services',
    'employee-onboard': 'Employee On-Board',
    'mileage-reimbursement': 'Mileage Reimbursement',
    'vacation-leave': 'Vacation Request',
    'transport-request': 'Transportation Request',
    'stationary-request': 'Stationary Request',
    'attendance-regularisation': 'Attendance Regularisation',
    'purchase-request': 'Purchase Request',
    'asset-order': 'Asset Order',
    'leave-cancel': 'Leave Cancel Request',
    'asset-request': 'Asset Request',
};

//moved to EFormConfiguration.json
// export const workflowPrivilege = {
//     'attendance-regularisation': ['nuacare:wf:attendanceRegularisation'],
//     'leave': ['nuacare:wf:leaveRequest'],
//     'advance-salary': ['nuacare:wf:advanceSalary'],
//     'vacation-leave': ['nuacare:wf:vacationRequest'],
//     'leave-cancel': ['nuacare:wf:leaveCancel'],
//     'transport-request': ['nuacare:wf:transportationRequest'],
//     'stationary-request': ['nuacare:wf:stationaryRequest'],
//     'purchase-request': ['nuacare:wf:purchaseRequest'],
//     'asset-order': ['nuacare:wf:assetWorkOrder'],
//     'asset-request': ['nuacare:wf:assetPurchaseRequest'],
//     'petty-cash': ['nuacare:wf:pettyCash'],
//     'general-service': ['nuacare:wf:itHelpDeskRequest'],
//     'employee-onboard': ['nuacare:emp:add'],
// };

// export const mapWorkflowNameToTitle = {
//     'employeeLeaveRequestProcess': 'Leave Request',
//     'travelRequests': 'Travel Request',
//     'expenseApproval': 'Expense Approval',
//     'employeeAdvanceLoanProcess': 'Advance Loan',
//     'employeeAdvanceSalaryProcess': 'Employee Advance Salary',
//     'employeePettyCashProcess': 'Petty Cash',
//     'employeeResignationProcess': 'Employee Resignation',
//     'employeeOvertimeProcess': 'Overtime Claim',
//     'emoloyeeExitChecklistProcess': 'Employee Exit',
//     'itHelpDeskRequestProcess': 'IT Services',
//     'employeeOnboardingProcess': 'Employee On-Board',
//     'employeeMileageReimbursement': 'Mileage Reimbursement',
//     'transportationRequestProcess': 'Transportation Request',
//     'employeeStationaryRequestProcess': 'Stationary Request',
//     'employeeAttendanceRegularisationProcess': 'Attendance Regularisation',
//     'employeePurchaseRequestProcess': 'Purchase Request',
//     'assetWorkOrderRequestProcess': 'Asset Order',
//     'employeeVacationRequestProcess': 'Vacation Request',
//     'employeeLeaveCancelRequestProcess': 'Leave Cancel Request',
//     'employeeAssetRequestProcess': 'Asset Request',
// };

// export const mapWorkflowStateToName = {
//     'employeeLeaveRequest': 'Create Request',
//     'leaveRequestManagerApproval': 'Manager Approval',
//     'leaveRequestDepartmentHeadApproval': 'Hr Manager Approval',
//     'leaveRequestHRApproval': 'Department Head Approval',
//     'advanceSalaryRequest': 'Create Request',
//     'advanceSalaryManagerApproval': 'Manager Approval',
//     'advanceSalaryFinanceApproval': 'Finance Approval',
//     'advanceSalaryFinanceProcessing': 'Payroll Processing',
//     'pettyCashRequest': 'Create Request',
//     'pettyCashManagerApproval': 'Manager Approval',
//     'pettyCashFinanceApproval': 'Finance Approval',
//     'pettyCashFinanceProcessing': 'Finance Processing',
//     'overtimeRequest': 'General Section',
//     'overtimeSupervisorApproval': 'Supervisor Approval',
//     'overtimeHRAproval': 'HR Approval',
//     'overtimePayrollProcessing': 'Payroll Processing',
//     'helpDeskRequest': 'Create Request',
//     'helpDeskServiceProcessing': 'Service Desk Processing',
//     'stage1': 'Create Employee',
//     'stage2': 'Update Employee Information',
//     'stage3': 'Document Upload',
//     'stage4': 'Manager Approval',
//     'stage5': 'Allocate IT Assets',
//     'mileageReimbursementRequest': 'General Section',
//     'mileageReimbursementSupervisorApproval': 'Supervisor Approval',
//     'mileageReimbursementFinanceProcessing': 'Finance Processing',
//     'transportationRequest': 'Create Request',
//     'transportationRequestManagerApproval': 'Manager Approval',
//     'transportationRequestAdminApproval': 'Processing',
//     'stationaryRequest': 'Create Stationary Request',
//     'stationaryRequestManagerApproval': 'Manager Approval',
//     'stationaryRequestAdminApproval': 'Processing',
//     'purchaseRequest': 'Create Purchase Request',
//     'purchaseRequestManagerApproval': 'Manager Approval',
//     'purchaseRequestAdminApproval': 'Processing',
//     'attendanceRegularisationRequest': 'Create Request',
//     'attendanceManagerApproval': 'Manager Approval',
//     'assetRequest': 'Create Asset Request',
//     'assetRequestManagerApproval': 'Manager Approval',
//     'assetRequestAdminApproval': 'Processing',
//     'employeeVacationRequest': 'Create Request',
//     'vacationRequestmanagerApproval': 'Manager Approval',
//     'vacationRequestDepartmentHeadApproval': 'Hr Manager Approval',
//     'vacationRequestHRApproval': 'Department Head Approval',
//     'assetWorkOrderRequest': 'Create Request',
//     'assetWorkOrderRequestInchargeProcessing': 'Processing',
//     'employeeLeaveCancelRequest': 'Create Request',
//     'leaveCancelRequestManagerApproval': 'Manager Approval',
//     'leaveCancelRequestHRApproval': 'HR Processing',
// };

export const workflowPath = {
    leave: '/eform/leave/1',
    travel: '/eform/travel/1',
    expenseApproval: '/eform/expense-approval/1',
    advanceSalary: '/eform/advance-salary/1',
};

export const hrPayrollSidePane = [
    { text: 'payslip-batches', primaryText: 'PaySlip Batches' },
    { text: 'payslips', primaryText: 'Payslips' },
    { text: 'payment-advices', primaryText: 'Payment Advices' },
    // {text: 'payroll-reports', primaryText: 'Payroll Reports'},
];

export const buttonActions = {
    SUBMIT: 'SUBMIT',
    APPROVE: 'APPROVE',
    DONE: 'DONE',
    REJECT: 'REJECT',
    GET_CLARITY: 'GET_CLARITY',
    REASSIGN: 'REASSIGN',
    DRAFT: 'DRAFT',
    CANCEL: 'CANCEL',
};

export const inboxSidebar = [
    {
        name: 'Inbox',
        route: 'requests',
        key: '60b4e915-f693-41f7-8a2d-a95383412fbf',
        action: 'inbox',
        iconName: 'Inbox.svg'
    },
    {
        name: 'Draft',
        route: 'draft',
        action: 'draft',
        iconName: 'draft.svg',
        key: '60b4e915-f693-41f7-8a2d-a95383412frebf'
    },
    {
        name: 'Participated',
        route: 'participated',
        action: 'participated',
        iconName: 'participated.svg',
        key: '60b4e915-f693-48f7-8a2d-a95383412fbf'
    },
    {
        name: 'Approved',
        route: 'approved',
        action: 'approved',
        iconName: 'approved.svg',
        key: '60b4e915-f693-41f7-8a2d-a95323412fbf'
    },
    {
        name: 'Rejected',
        action: 'rejected',
        route: 'rejected',
        iconName: 'rejected.svg',
        key: '60b4e915-f693-41f7-8a2d-a93383412fbf'
    },
];

export const appShortcuts = [
    { title: 'Administration', svgUrl: 'Administration.svg', url: '' },
    { title: 'Analytics', svgUrl: 'Analytics.svg', url: '' },
    { title: 'Appointments', svgUrl: 'Appointment.svg', url: '' },
    { title: 'Billing', svgUrl: 'Billing.svg', url: '' },
    { title: 'Insurance', svgUrl: 'Insurance.svg', url: '' },
    { title: 'Laboratory', svgUrl: 'Laboratory.svg', url: '' },
    { title: 'Pharmacy', svgUrl: 'Pharmacy.svg', url: '' },
    { title: 'Radiology', svgUrl: 'Radiology.svg', url: '' },
    { title: 'Registration', svgUrl: 'Registration.svg', url: '' },
];

export const leaveYears = [
    {
        label: 'Mar 1995 - Jun 1996',
        value: 1995,
    },
    {
        label: 'Mar 1996 - Jun 1997',
        value: 1996,
    },
];

export const backgroundColorsForShifts = {
    M: '#7EB7E2',
    D: '#7EB7E2',
    E: '#E0E77B',
    L: '#F18384',
    N: '#60EEA5',
    O: '#DF00FF',
};

export const SLOT_TYPES = {
    APPOINTMENT: { label: 'Appointment', color: '#00b300', value: 'APPOINTMENT' },
    WALK_IN: { label: 'Walk in', color: '#007acc', value: 'WALK_IN' },
    RECESS: { label: 'Recess', color: '#ff751a', value: 'RECESS' },
    OFF: { label: 'Off', color: 'rgba(188,193,178,0.81)', value: 'OFF' },
    APPOINTMENT_BOOKED: { label: 'Appointment Booked', color: '#FF3D00', value: 'APPOINTMENT_BOOKED' },
    CHECKED_IN: { label: 'Checked In', color: '#00b300', value: 'CHECKED_IN' },
};

export const tokenTypes = {
    VIP: 'VIP',
    NORMAL: 'Normal',
};

export const getAppointmentColor = {
    'checked in': '#00b300',
    provisional: '#d6d600',
    checkedinlate: '#ff751a',
    'walk-in': '#007acc',
    closed: '#8a8a5c',
    confirmed: '#00bfff',
    empty: '#8a8a5c',
};

export const appointmentTypes = [
    {
        key: 'checkedIn',
        value: 'Checked In',
    },
    {
        key: 'provisional',
        value: 'Provisional',
    },
    {
        key: 'checkedInLate',
        value: 'Checked In Late',
    },
    {
        key: 'walkIn',
        value: 'Walk In'
    },
    {
        key: 'confirmed',
        value: 'Confirmed',
    },
    {
        key: 'empty',
        value: 'Empty',
    }
];

export const defaultRowsPerPageOptions = [5, 10, 25, 50];

export const shiftMenu = [
    {
        label: 'M',
        value: 'M',
        background: '#7EB7E2',
    },
    {
        label: 'E',
        value: 'E',
        background: '#E0E77B',
    },
    {
        label: 'N',
        value: 'N',
        background: '#60EEA5',
    },
];


export const profileForm = 'profileForm';

export const ATTENDANCE_ACTION = {
    SIGN_IN: 'sign_in',
    SIGN_OUT: 'sign_out',
};

export const BMIStatus = {
    underWeight: 'Underweight',
    normal: 'Normal',
    overweight: 'Overweight',
    obese: 'Obese',
};

export const APPLICATION_CONFIG_URL = '/nuacare-config/employee-dashboard';

export const CONSULTATION_APPLICATION_CONFIG_URL = '/nuacare-config/consultation';

export const DOC_APPLICATION_CONFIG_URL = '/nuacare-config/docmanage';

export const COMMON_APPLICATION_CONFIG_URL = '/nuacare-config/common';

export const EVENT_ACTIONS = {
    OTHERS_LEAVES: 'OTHERS_LEAVE',
    MY_LEAVES: 'MY_LEAVES',
    HOLIDAY: 'HOLIDAY',
    WORK_HOURS: 'WORK_HOURS',
};

export const cardPrintHTML = `${APPLICATION_CONFIG_URL}/Registration/PatientCard/PatientCard.html`;
export const patientLabelPrintHTML = `${APPLICATION_CONFIG_URL}/Registration/PatientLabel/PatientLabel.html`;
export const invoicePrintHTML = `${APPLICATION_CONFIG_URL}/Registration/billtoken.html`;
export const cardPrintAfterVisitHTML = `${APPLICATION_CONFIG_URL}/PrintCard/PrintCard.html`;

export const PAID_ON_ACCOUNT_CREATE_PRIV = ['accounting.create.paidOnAccount'];
export const PAID_ON_ACCOUNT_EDIT_PRIV = ['accounting.edit.paidOnAccount'];
export const PAID_ON_ACCOUNT_REFUND_PRIV = ['accounting.refund.paidOnAccount'];

export const noPrivilegeMessage = (
    <div className="flexChildren justify-content-center font-size-2 mt-2">
        You Do Not Have Access To This Module
    </div>
);

export const rightToLeftLanguages = ['ar'];

export const emrDownloadAPI =
    '/nuacare-core/rest/nuacare/v1/file/download?path=emr&name=';

export const STOCK_MANAGEMENT_PRIVILEGES = {
    showDrugTab: ['erp.show.drug'],
    showCategories: ['erp.show.categories'],
    showConsumablesTab: ['erp.show.consumables'],
    showOpticalProductsTab: ['erp.show.optical.products'],
    showInventoryTab: ['erp.show.inventory'],
    showReorderRules: ['erp.show.reorder.rules'],
    purchaseOrder: ['erp.create.purchaseOrder'],
    addStock: ['erp.create.addStock'],
    stockMove: ['erp.create.stockMove'],
    miscOps: ['erp.create.miscOps'],
    internalMove: ['erp.create.internalMove'],
    adjustInventory: ['erp.create.adjustInventory'],
    returnStock: ['erp.create.returnStock'],
    stockPicking: ['erp.show.stockPicking'],
    receiveStock: ['erp.create.receiveStock'],
    advancedSearch: ['erp.stock.advanced.search.icon'],
    stockIntent: ['erp.create.intent'],
    materialReceive: ['erp.create.materialReceive'],
};

export const BOOK_MANAGEMENT_PRIVILEGES = {
    // paidOnAccount: PAID_ON_ACCOUNT_CREATE_PRIV,
    payOrReceiveOnAccount: ['accounting.create.payOrReceiveOnAccount'],
    cashPaymentVoucher: ['accounting.create.cashPaymentVoucher'],
    expense: ['accounting.create.expense'],
    purchaseBill: ['accounting.create.purchaseBill'],
    issueInvoice: ['accounting.create.issueInvoice'],
    payBill: ['accounting.create.payBill'],
    prepaidExpense: ['accounting.create.prepaidExpense'],
    creditMemo: ['accounting.create.creditMemo'],
    debitMemo: ['accounting.create.debitMemo'],
    transfer: ['accounting.create.transfer'],
    journalEntries: ['accounting.journal'],
    pettyCash: ['accounting.create.pettyCash'],
    cashClose: ['accounting.create.cashClose'],
    salaryAdvance: ['accounting.create.salaryAdvance'],
    employeeExpense: ['accounting.create.employeeExpense'],
    patientInvoices: ['accounting.show.patientInvoices'],
    paymentReceived: ['accounting.show.paymentReceived'],
    costAccountLines: ['accounting.show.costAccountLines'],
    consolidatedCashClose: ['accounting.create.consolidatedCashClose'],
};

export const SETTING_PRIVILEGE = 'nuacare.app.setting';
export const CASH_CLOSE_PRIVILEGE = 'enable.my.cash.close';
export const PAYER_ADMIN = 'nuacare.payer.admin';
export const HR_ADMIN = 'nuacare.hr.admin';
export const RADIOLOGIST_PRIVILEGE = 'ris';
export const ORDERS_QUEUE_PRIVILEGE = 'ordersQueue';
export const INSURANCE_BUNDLE_PRIVILEGE = 'payer.bundles';
export const INSURANCE_APPROVAL_PRIV = 'payer.approvals';
export const INSURANCE_REQUESTS_PRIV = 'payer.insurance.requests';
export const INSURANCE_CLAIM_PRIV = 'payer.claims';
export const INSURANCE_INVOICES_PRIV = 'payer.invoices';

export const STOCK_PURCHASE_ORDERS_PRIVILEGE = 'stock.purchase.orders';
export const STOCK_PURCHASE_SUPPLIERS_PRIVILEGE = 'stock.purchase.supplier';
export const STOCK_PURCHASE_PAYMENT_INVOICES_PRIVILEGE = 'stock.purchase.invoices';
export const STOCK_PURCHASE_PAYMENTS_PRIVILEGE = 'stock.purchase.payments';
export const STOCK_PRODUCT_DISPATCH_PRIVILEGE = 'stock.purchase.dispatch'

export const APP_SHORTCUT_PRIVILEGE = 'nuacare.app.showAppShortcuts';
export const WORK_BOX_PRIVILEGE = 'nuacare.show.workbox';

const getWorkflowPrivileges = (workflowPrivilegeConfig) => {
    const workflowPrivs = {};
    if (isObjectValidAndNotEmpty(workflowPrivilegeConfig)) {
        Object.keys(workflowPrivilegeConfig).forEach(aKey => {
            // temporary fix.. need to change route level checks properly
            for (let i = 0; i < 10; i += 1) {
                workflowPrivs[`${eform}/${aKey}/${i + 1}`] = {
                    privileges: workflowPrivilegeConfig[aKey],
                };
            }
        });
    }
    return workflowPrivs;
};

export const pathPrivileges = {
    [rootPage]: {
        privileges: [''],
    },
    [opd]: {
        privileges: opdDoctorPrivilege,
    },
    [inbox]: {
        privileges: ['hr.eforms'],
    },
    [opdQueue]: {
        privileges: opdDoctorPrivilege,
    },
    [opdAppointments]: {
        privileges: opdDoctorPrivilege,
    },
    [ipd]: {
        privileges: opdDoctorPrivilege,
    },
    [nurseQueue]: {
        privileges: ['nursedesk'],
    },
    [proceduresQueue]: {
        privileges: ['proceduresqueue'],
    },
    [technicianQueue]: {
        privileges: ['radiologyqueues'],
    },
    [appointments]: {
        privileges: ['appointments'],
    },
    [registration]: {
        privileges: ['registration'],
    },
    [appointmentsSetting]: {
        privileges: ['appointments.admin'],
    },
    [appointmentsSettingDepartments]: {
        privileges: ['appointments.admin'],
    },
    [workBoxMailPage]: {
        privileges: [WORK_BOX_PRIVILEGE],
    },
    [patientDocumentsPage]: {
        privileges: ['app:patient-documents'],
    },
    [appointmentsSettingServices]: {
        privileges: ['appointments.admin'],
    },
    [appointmentsSettingTemplates]: {
        privileges: ['appointments.admin'],
    },
    [appointmentsList]: {
        privileges: ['appointments'],
    },
    [appointmentsListing]: {
        privileges: ['appointments'],
    },
    [appointmentsRecallListing]: {
        privileges: ['appointments'],
    },
    [dashboard]: {
        privileges: ['dashboard'],
    },
    [profile]: {
        privileges: ['profile'],
    },
    [organization]: {
        privileges: ['hr.organisation'],
    },
    [stockManagement]: {
        privileges: ["stock"],
    },
    [purchaseOrder]: {
        privileges: ['hr.organisation'],
    },
    [stockAdvancedSearch]: {
        privileges: STOCK_MANAGEMENT_PRIVILEGES.advancedSearch,
    },
    [organizationTree]: {
        privileges: ['hr.organisation'],
    },
    [organizationList]: {
        privileges: ['hr.organisation'],
    },
    [payroll]: {
        privileges: ['hr.salary'],
    },
    [paySlipBatches]: {
        privileges: ['hr.payroll'],
    },
    [editPaymentAdvice]: {
        privileges: ['hr.payroll'],
    },
    [newPaySlipBatches]: {
        privileges: ['hr.payroll'],
    },
    [myPayslip]: {
        privileges: ['dashboard'],
    },
    [editPaySlipBatches]: {
        privileges: ['hr.payroll'],
    },
    [payslipInput]: {
        privileges: ['hr.payroll'],
    },
    [paymentAdvices]: {
        privileges: ['hr.payroll'],
    },
    [payslips]: {
        privileges: ['hr.payroll'],
    },
    [payrollReports]: {
        privileges: ['hr.payroll'],
    },
    [payrollHr]: {
        privileges: ['hr.payroll'],
    },
    [leaves]: {
        privileges: leavePrivilege,
    },
    [leaveBasePage]: {
        privileges: leavePrivilege,
    },
    [leavesBalances]: {
        privileges: organisationAdmin,
    },
    [eform]: {
        privileges: ['hr.eforms'],
    },
    [documents]: {
        privileges: ['hr.documents'],
    },
    [documentsHr]: {
        privileges: ['hr.documents'],
    },
    [holidays]: {
        privileges: ['hr.holidays'],
    },
    [addNewHoliday]: {
        privileges: ['hr.holidays.admin'],
    },
    [createNewHoliday]: {
        privileges: ['hr.holidays.admin'],
    },
    [viewHolidays]: {
        privileges: ['hr.holidays'],
    },
    [dutyRoster]: {
        privileges: ['hr.duty.roster'],
    },
    [assets]: {
        privileges: ['hr.assets'],
    },
    [assetsCreate]: {
        privileges: ['hr.assets.admin'],
    },
    [assetsEdit]: {
        privileges: ['hr.assets.admin'],
    },
    [assetsView]: {
        privileges: ['hr.assets'],
    },
    [announcements]: {
        privileges: ['hr.announcements'],
    },
    [announcementsCreate]: {
        privileges: ['hr.announcements.admin'],
    },
    [announcementsEdit]: {
        privileges: ['hr.announcements.admin'],
    },
    [workflow]: {
        privileges: [],
    },
    [patientSearch]: {
        privileges: ['clinicalsearch'],
    },
    [patientActive]: {
        privileges: searchActivePatientPrivilege,
    },
    [patientRecent]: {
        privileges: opdDoctorPrivilege,
    },
    [patientAll]: {
        privileges: searchAllPatientPrivilege,
    },
    [analytics]: {
        privileges: ['nuacare:analytics:dashboard'],
    },
    [stockManagementDrugs]: {
        privileges: STOCK_MANAGEMENT_PRIVILEGES.showDrugTab,
    },
    [stockManagementProductCategories]: {
        privileges: STOCK_MANAGEMENT_PRIVILEGES.showCategories,
    },
    [stockManagementConsumables]: {
        privileges: STOCK_MANAGEMENT_PRIVILEGES.showConsumablesTab,
    },
    [stockManagementBatches]: {
        privileges: STOCK_MANAGEMENT_PRIVILEGES.showInventoryTab,
    },
    [patientAll]: {
        privileges: ['emr.show.patientSearchAll'],
    },

    // purchase routes
    [supplierInvoices]: {
        privileges: [STOCK_PURCHASE_PAYMENT_INVOICES_PRIVILEGE],
    },
    [purchaseSuppliers]: {
        privileges: [STOCK_PURCHASE_SUPPLIERS_PRIVILEGE],
    },
    [purchasePayments]: {
        privileges: [STOCK_PURCHASE_PAYMENTS_PRIVILEGE],
    },
    [purchaseOrders]: {
        privileges: [STOCK_PURCHASE_ORDERS_PRIVILEGE],
    },
    [productDispatch]: {
        privileges: [STOCK_PRODUCT_DISPATCH_PRIVILEGE],
    },


    [payors]: {
        privileges: ['insurance'],
    },
    [payorApproval]: {
        privileges: [INSURANCE_APPROVAL_PRIV],
    },
    [insuranceRequests]: {
        privileges: [INSURANCE_REQUESTS_PRIV],
    },
    [payorInvoice]: {
        privileges: [INSURANCE_INVOICES_PRIV],
    },
    [payorClaims]: {
        privileges: [INSURANCE_CLAIM_PRIV],
    },
    [payorClaimsDetails]: {
        privileges: [INSURANCE_CLAIM_PRIV],
    },
    [payorBundles]: {
        privileges: [INSURANCE_BUNDLE_PRIVILEGE],
    },
    [payorBundlesDetails]: {
        privileges: [INSURANCE_BUNDLE_PRIVILEGE],
    },
    [insuranceRequests]: {
        privileges: [INSURANCE_REQUESTS_PRIV],
    },
    [payorNetwork]: {
        privileges: [INSURANCE_CLAIM_PRIV],
    },
    [payorSubInsurance]: {
        privileges: [INSURANCE_CLAIM_PRIV],
    },
    [payorPolicy]: {
        privileges: [INSURANCE_CLAIM_PRIV],
    },
    [payerScheme]: {
        privileges: [INSURANCE_CLAIM_PRIV],
    },
    [bookManagement]: {
        privileges: ['accounting'],
    },
    [bookManagementJournalEntries]: {
        privileges: BOOK_MANAGEMENT_PRIVILEGES.journalEntries,
    },
    [bookManagementAccount]: {
        privileges: ['accounting.accounts'],
    },
    [bookManagementAging]: {
        privileges: ['accounting.aging'],
    },
    [bookManagementSearch]: {
        privileges: ['accounting.search'],
    },
    [bookManagementAnalyticalAccount]: {
        privileges: ['accounting.analytic'],
    },
    [bookManagementPayerCollection]: {
        privileges: ['accounting.payerCollection'],
    },
    [bookManagementBankReconciliation]: {
        privileges: ['accounting.bankReconciliation'],
    },
    [bookManagementCardReconciliation]: {
        privileges: ['accounting.cardReconciliation'],
    },
    [bookManagementPaymentVoucher]: {
        privileges: ['accounting.paymentVoucher'],
    },
    [bookManagementDebitAndCreditMemo]: {
        privileges: ['accounting.DebitAndCreditMemo'],
    },
    [purchaseSuppliers]: {
        privileges: ['accounting.supplier'],
    },
    /* --------Setting privilege--------------- */
    [settings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [services]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [bookSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [serviceCategory]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [booksSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [costAccountSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [billableProductService]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [analyticalDistributionService]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [orderableProductService]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [packageListService]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [generalSettingService]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [periodSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [myCashClose]: {
        privileges: [CASH_CLOSE_PRIVILEGE],
    },
    [periodicProcessingSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [fiscalYearSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [cashCloseTypesSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [payorSettings]: {
        privileges: [PAYER_ADMIN],
    },
    [payorList]: {
        privileges: [PAYER_ADMIN],
    },
    [payorProductCategory]: {
        privileges: [PAYER_ADMIN],
    },
    [sfdaGtin]: {
        privileges: [PAYER_ADMIN],
    },
    [policyViewList]: {
        privileges: [PAYER_ADMIN],
    },
    [insuranceSchemeViewList]: {
        privileges: [PAYER_ADMIN],
    },
    [notificationSetting]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [smsSetting]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [autoTextSettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [autoTextCategorySettings]: {
        privileges: [SETTING_PRIVILEGE],
    },
    [risUrls.home]: {
        privileges: [RADIOLOGIST_PRIVILEGE],
    },
    [risUrls.orders]: {
        privileges: [RADIOLOGIST_PRIVILEGE],
    },
    [risUrls.oldOrders]: {
        privileges: [RADIOLOGIST_PRIVILEGE],
    },
    [risUrls.orderDetail]: {
        privileges: [RADIOLOGIST_PRIVILEGE],
    },
    [risUrls.reports]: {
        privileges: [RADIOLOGIST_PRIVILEGE],
    },
    [risUrls.reportTemplates]: {
        privileges: [RADIOLOGIST_PRIVILEGE],
    },
    [ordersQueueUrls.home]: {
        privileges: [ORDERS_QUEUE_PRIVILEGE],
    },
    [ordersQueueUrls.orders]: {
        privileges: [ORDERS_QUEUE_PRIVILEGE],
    },
    [ordersQueueUrls.oldOrders]: {
        privileges: [ORDERS_QUEUE_PRIVILEGE],
    },
    [ordersQueueUrls.orderDetail]: {
        privileges: [ORDERS_QUEUE_PRIVILEGE],
    },
    [ordersQueueUrls.reports]: {
        privileges: [ORDERS_QUEUE_PRIVILEGE],
    },
    [ordersQueueUrls.reportTemplates]: {
        privileges: [ORDERS_QUEUE_PRIVILEGE],
    },
    ...getWorkflowPrivileges(),
    /*-------------------------------------------*/
};

export const pathPrivilegesWithEforms = (eformPrivilegeConfig) => ({
    ...cloneDeep(pathPrivileges),
    ...getWorkflowPrivileges(eformPrivilegeConfig)
});


export const hijriDateFormat = 'iYYYY/iMM/iDD';

export const ERP_TRANSACTION_TYPES = [
    'PURCHASE_ORDER',
    'PURCHASE_BILL',
    'SALE_ORDER',
    'ACCOUNT_MOVE',
    'ACCOUNT_EXPENSE',
    'RECEIVE_STOCK',
    'CUSTOMER_REFUND',
    'SUPPLIER_REFUND',
    'CUSTOMER_INVOICE',
    'ADD_STOCK',
    'BILL_PAYMENT',
    'PAYMENTS_RECEIVED',
    'FUND_TRANSFER',
    'INVENTORY_ADJUSTMENT',
    'SELL_STOCK',
    'INTERNAL_MOVE',
    'RETURN_STOCK',
    'EMPLOYEE_ADVANCE_SALARY',
    'PETTY_CASH',
    'PREPAID_EXPENSE',
    'CASH_CLOSE',
    'PETTY_CASH_EXPENSE',
    'SUPPLIER_ADVANCE',
    'SUPPLIER_ADVANCE_REFUND',
    'INTENT',
    'MATERIAL_RECEIVE',
    'CONSOLIDATED_CASH_CLOSE'
];
export const ERP_TRANSACTION_TYPES_PRIVILEGES = {
    [ERP_TRANSACTION_TYPES[0]]: STOCK_MANAGEMENT_PRIVILEGES.purchaseOrder,
    [ERP_TRANSACTION_TYPES[1]]: BOOK_MANAGEMENT_PRIVILEGES.purchaseBill,
    [ERP_TRANSACTION_TYPES[2]]: [],
    [ERP_TRANSACTION_TYPES[3]]: BOOK_MANAGEMENT_PRIVILEGES.journalEntries,
    [ERP_TRANSACTION_TYPES[4]]: BOOK_MANAGEMENT_PRIVILEGES.expense,
    [ERP_TRANSACTION_TYPES[5]]: BOOK_MANAGEMENT_PRIVILEGES.receiveStock,
    [ERP_TRANSACTION_TYPES[6]]: [],
    [ERP_TRANSACTION_TYPES[7]]: BOOK_MANAGEMENT_PRIVILEGES.creditMemo,
    [ERP_TRANSACTION_TYPES[8]]: BOOK_MANAGEMENT_PRIVILEGES.patientInvoices,
    [ERP_TRANSACTION_TYPES[9]]: STOCK_MANAGEMENT_PRIVILEGES.addStock,
    [ERP_TRANSACTION_TYPES[10]]: BOOK_MANAGEMENT_PRIVILEGES.payBill,
    [ERP_TRANSACTION_TYPES[11]]: BOOK_MANAGEMENT_PRIVILEGES.paymentReceived,
    [ERP_TRANSACTION_TYPES[12]]: BOOK_MANAGEMENT_PRIVILEGES.transfer,
    [ERP_TRANSACTION_TYPES[13]]: STOCK_MANAGEMENT_PRIVILEGES.adjustInventory,
    [ERP_TRANSACTION_TYPES[14]]: [],
    [ERP_TRANSACTION_TYPES[15]]: STOCK_MANAGEMENT_PRIVILEGES.internalMove,
    [ERP_TRANSACTION_TYPES[16]]: STOCK_MANAGEMENT_PRIVILEGES.returnStock,
    [ERP_TRANSACTION_TYPES[17]]: BOOK_MANAGEMENT_PRIVILEGES.salaryAdvance,
    [ERP_TRANSACTION_TYPES[18]]: BOOK_MANAGEMENT_PRIVILEGES.pettyCash,
    [ERP_TRANSACTION_TYPES[19]]: BOOK_MANAGEMENT_PRIVILEGES.prepaidExpense,
    [ERP_TRANSACTION_TYPES[20]]: BOOK_MANAGEMENT_PRIVILEGES.cashClose,
    [ERP_TRANSACTION_TYPES[21]]: BOOK_MANAGEMENT_PRIVILEGES.expense,
    [ERP_TRANSACTION_TYPES[22]]: BOOK_MANAGEMENT_PRIVILEGES.paidOnAccount,
    [ERP_TRANSACTION_TYPES[23]]: PAID_ON_ACCOUNT_REFUND_PRIV,
    [ERP_TRANSACTION_TYPES[24]]: STOCK_MANAGEMENT_PRIVILEGES.purchaseOrder,
    [ERP_TRANSACTION_TYPES[25]]: STOCK_MANAGEMENT_PRIVILEGES.purchaseOrder,
    [ERP_TRANSACTION_TYPES[26]]: BOOK_MANAGEMENT_PRIVILEGES.consolidatedCashClose,
};

export const ERP_STOCK_TRANSACTION_TYPES = [
    ERP_TRANSACTION_TYPES[0], ERP_TRANSACTION_TYPES[5], ERP_TRANSACTION_TYPES[9],
    ERP_TRANSACTION_TYPES[13], ERP_TRANSACTION_TYPES[14], ERP_TRANSACTION_TYPES[15],
    ERP_TRANSACTION_TYPES[16], ERP_TRANSACTION_TYPES[24], ERP_TRANSACTION_TYPES[25]
];

export const ERP_BOOK_TRANSACTION_TYPES = [
    ERP_TRANSACTION_TYPES[1], ERP_TRANSACTION_TYPES[3], ERP_TRANSACTION_TYPES[4], ERP_TRANSACTION_TYPES[6],
    ERP_TRANSACTION_TYPES[8], ERP_TRANSACTION_TYPES[10], ERP_TRANSACTION_TYPES[11],
    ERP_TRANSACTION_TYPES[12], ERP_TRANSACTION_TYPES[17], ERP_TRANSACTION_TYPES[18], ERP_TRANSACTION_TYPES[19],
    ERP_TRANSACTION_TYPES[20], ERP_TRANSACTION_TYPES[21], ERP_TRANSACTION_TYPES[22], ERP_TRANSACTION_TYPES[23], ERP_TRANSACTION_TYPES[26]
];

export const ERP_TRANSACTION_TYPES_DISPLAY = {
    [ERP_TRANSACTION_TYPES[0]]: 'Purchase Order',
    [ERP_TRANSACTION_TYPES[1]]: 'Purchase Invoice',
    [ERP_TRANSACTION_TYPES[2]]: 'Sale Order',
    [ERP_TRANSACTION_TYPES[3]]: 'Account Move',
    [ERP_TRANSACTION_TYPES[4]]: 'Expense',
    [ERP_TRANSACTION_TYPES[5]]: 'Receive Stock',
    [ERP_TRANSACTION_TYPES[6]]: 'Customer Refund Invoice',
    [ERP_TRANSACTION_TYPES[7]]: 'Credit Memo',
    [ERP_TRANSACTION_TYPES[8]]: 'Patient Invoice',
    [ERP_TRANSACTION_TYPES[9]]: 'Add Stock',
    [ERP_TRANSACTION_TYPES[10]]: 'Supplier Payment',
    [ERP_TRANSACTION_TYPES[11]]: 'Pay Invoice',
    [ERP_TRANSACTION_TYPES[12]]: 'Fund Transfer',
    [ERP_TRANSACTION_TYPES[13]]: 'Adjust Inventory',
    [ERP_TRANSACTION_TYPES[14]]: 'Out Stock',
    [ERP_TRANSACTION_TYPES[15]]: 'StockMove',
    [ERP_TRANSACTION_TYPES[16]]: 'Return Stock',
    [ERP_TRANSACTION_TYPES[17]]: 'Salary Advance',
    [ERP_TRANSACTION_TYPES[18]]: 'Petty Cash',
    [ERP_TRANSACTION_TYPES[19]]: 'Prepaid Expense',
    [ERP_TRANSACTION_TYPES[20]]: 'Cash Close',
    [ERP_TRANSACTION_TYPES[21]]: 'Petty Cash Recoup',
    [ERP_TRANSACTION_TYPES[22]]: 'Account Payment',
    [ERP_TRANSACTION_TYPES[23]]: 'Account Payment Refund',
    [ERP_TRANSACTION_TYPES[24]]: 'Stock Intent',
    [ERP_TRANSACTION_TYPES[25]]: 'Material Receive',
    [ERP_TRANSACTION_TYPES[26]]: 'Consolidated Cash Close',

};

export const DATE_FILTERS = {
    TODAY: 'Today',
    YESTERDAY_AND_TODAY: 'Yesterday and Today',
    THIS_WEEK: 'This Week',
    THIS_MONTH: 'This Month',
    PAST_7_DAYS: 'Past 7 Days',
    PAST_30_DAYS: 'Past 30 Days',
    CUSTOM: 'Custom',
};

export const ADVANCE_SEARCH_OPERATORS = {
    EQUALS: 'equals',
    CONTAINS: 'containing',
    LESS_THAN_OR_EQUALS: '<=',
    GREATER_THAN_OR_EQUALS: '>=',
    BETWEEN: 'between',
};

export const PAYMENT_METHODS = [
    {
        label: 'Bank Transfer',
        value: 'BANKTRANSFER',
    },
    {
        label: 'Cash',
        value: 'CASH',
    },
    {
        label: 'Cheque',
        value: 'CHEQUE',
    }
];

export const PAYMENT_METHODS_MAP = {
    BANKTRANSFER: PAYMENT_METHODS[0],
    CASH: PAYMENT_METHODS[1],
    CHEQUE: PAYMENT_METHODS[2]
};

export const COLORS = [
    '#9C27B0', '#FFC107', '#4CAF50', '#F44336',
    '#880E4F', '#E91E63', '#03A9F4', '#00BCD4',
    '#3F51B5', '#FF5722', '#607D8B', '#795548',
    '#FF9800', '#FFEE58', '#2962FF', '#E65100',
    '#2196F3',
];

// to be used with shade ex. amber[500]
export const MATERIAL_COLORS = [
    amber,
    blue,
    blueGrey,
    brown,
    cyan,
    deepOrange,
    deepPurple,
    green,
    grey,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow,
];

export const applicationDateInputMask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
export const applicationDateTimeInputMask = [
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    "/",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    ":",
    /\d/,
    /\d/,
    " ",
    /[ap]/i,
    "M",
];

export const timeMask24Hours = [/\d/, /\d/, ':', /\d/, /\d/];
export const timeMask12Hours = [/\d/, /\d/, ':', /\d/, /\d/, ' ', /a|p/i, 'M'];

export const applicationDateFormat = 'dd/MM/yyyy';
export const applicationDateAndTimeFormat = 'dd/MM/yyyy hh:mm a';
export const applicationTimeFormat = 'hh:mm a';
export const applicationTimeDurationFormat = 'HH:mm';

export const ORDER_OPERATION = {
    NO_CHANGE: '0',
    CREATE: '1',
    UPDATE: '2',
    DELETE: '3',
    REJECT: '4',
};

export const fileUploadSizeLimit = 25000000;

export const approvalFilter = {
    fromDate: 'sale_order.createDate',
    toDate: 'sale_order.createDate',
    company: 'visit_attributes.xCompanyName',
    doctor: 'visit_attributes.xDoctor',
    patientId: 'sale_order.partnerId',
    patientName: 'res_partner.name',
    min: 'sale_order.amountTotal',
    max: 'sale_order.amountTotal',
};

export const invoiceFilter = {
    fromDate: 'account_invoice.createDate',
    toDate: 'account_invoice.createDate',
    paid: 'account_invoice.paid',
    submitted: 'account_invoice.submitted',
    doctor: 'episode_provider.actingProvider',
    patientId: 'account_invoice.partnerId',
    patientName: 'res_partner.name',
    invoiceNumber: 'account_invoice.number',
    policyNumber: 'episode_of_care.policyNumber'
};

export const appointmentStatuses = {
    PENDING: 'Pending',
    CONFIRMED: 'Confirmed',
    TENTATIVE: 'Tentative',
    COMPLETED: 'Completed',
    CANCELED: 'Canceled',
    CHECKED_IN: 'Checked In',
    DOCTOR_CANCELED: 'Doctor Canceled',
    RESCHEDULED: 'Voided',
    MISSED: 'Missed',
};

export const applicationShortcuts = {
    WARNING_DIALOG_YES: 'shift+z',
    WARNING_DIALOG_NO: 'shift+x',
};

export const rowHighlightColor = '#E3F2FD';
export const rowErrorColor = '#FF7D7D';

export const REPORT_TYPE = {
    HTML: 'HTML',
    PDF: 'PDF',
    SHOW_PDF: 'SHOW_PDF',
    CSV: 'CSV',
    EXCEL: 'EXCEL',
};

export const headerRowStyle = { background: '#fafafa' };
export const headerColStyle = { color: '#000', fontSize: '14px' };

export const imageFileTypes = ['jpg', 'jpeg', 'JPG', 'JPEG', 'png', 'PNG'];
export const documentFileTypes = ['pdf', 'PDF'];

export const registrationVersions = {
    V1: 'v1',
    V2: 'v2',
};

export const printPageSizes = {
    A4: {
        width: 8.3,
        height: 11.7,
    },
    A5: {
        width: 5.8,
        height: 8.3,
    },
    PATIENT_LABEL: {
        width: 4,
        height: 3,
    },
};

export const frontDeskRoutes = [
    appointments,
    registration,
    registrationV2,
    registrationV3,
    appointmentsSetting,
    appointmentsSettingDepartments,
    appointmentsSettingServices,
    appointmentsSettingTemplates,
    appointmentsList,
    appointmentsListing,
    appointmentsWaitingListing,
    appointmentsRecallListing,
];

export const nursingRoutes = [
    nurseQueue,
];

export const payorRoutes = [
    payorApproval,
];

export const commonHeaders = {
    headers: { 'Content-Type': 'application/json' },
};

export const csvImportName = {
    insurance_price_list: 'insurance_price_list',
    ORDERABLES: 'ORDERABLES',
    BILLABLES: 'JUST_BILLABLE',
    DRUG: 'DRUG',
    CONSUMABLES: 'CONSUMABLE',
    RE_ORDER_RULES: 'RE_ORDER_RULES',
    ATTENDANCE_PUNCHES: 'ATTENDANCE_PUNCHES',
    STOCK: 'STOCK',
    STOCK_BY_PO: 'STOCK_BY_PO',
    SUPPLIER: 'SUPPLIER',
    ATTENDANCE_MONTHLY_CONSOLIDATED: 'ATTENDANCE_MONTHLY_CONSOLIDATED',
    PACKAGES: 'PACKAGES',
};

export const scannerTypes = {
    FLATBED: 'FLATBED',
    ID: 'ID',
};

export const dutyRosterLocationTag = 'DUTY_ROSTER';
export const locationNotSelected = 'Location Not Selected';
export const queueNotSelected = 'Queue Not Selected';

export const secondaryIdentifierKey = 'secondary_identifier_label';
export const secondaryIdentifierLabelReplaceString = `~${secondaryIdentifierKey}~`;

export const insuranceEligibilityStatus = {
    ELIGIBLE: 'ELIGIBLE',
};
export const defaultWarningMessage = 'Any unsaved Data will be lost. Do you wish to continue';

export const notificationTypes = {
    ABNORMAL_LAB_RESULT: 'ABNORMAL_LAB_RESULT',
    DOCUMENT_EXPIRY: 'DOCUMENT_EXPIRY',
    ANNOUNCEMENT: 'ANNOUNCEMENT',
    WORKFLOW: 'WORKFLOW',
};

export const orderType = {
    LAB_ORDER: 'Lab Order',
};

export const BarcodeParsers = {
    GS1: 'GS1',
    XML: 'XML',
};

export const defaultDocumentCreatorButtonList = ['saveReport', 'createTemplate'];
export const iframeUrlMap = {
    ADD_STOCK: '/add-stock',
    ADJUST_INVENTORY: '/adjust-inventory',
    INTERNAL_MOVES: '/internal-moves',
    RETURN_STOCK: '/return-stock',
    INTENT: '/intent',
    MATERIAL_RECEIVE: '/material-receive',
    MISCELLANEOUS_OPERATION: '/miscellaneous-operation',
    PURCHASE_ORDER: '/purchase-order',
    BULK_UPLOAD: '/bulk-upload',
    FORMULARY_UPLOAD: '/formulary-upload',
    PREDETERMINATION: '/predetermination',
    PRE_AUTHORIZATION: '/pre-authorization',
}
export const stockFormNames = {
    ADD_STOCK: 'addStockForm',
    PURCHASE_ORDER: 'purchaseOrderForm',
    STOCK_MOVE: 'stockMoveForm',
    MISCELLANEOUS_OPERATION: 'miscOperationsForm',
    ADJUST_INVENTORY: 'adjustInventoryForm',
    RETURN_STOCK: 'returnStockForm',
    MATERIAL_RECEIVE: 'materialReceiveForm',
    STOCK_INTENT: 'stockIntentForm',
}
export const IMPLEMENTATION_LOCATIONS = {
    BAHRAIN: 'bahrain'
}
export const ADD_ACCOUNT_PRIVILEGES = {
    supplierCreateAccount: ['supplier.account.create'],
    payerCreateAccount: ['payer.account.create'],
};

export const PRICE_FOR_QUANTITY_ROUNDING_DIGITS = 5;
export const MAX_ITERATIONS_FOR_DISCOUNT_APPLYING = 5;

export const GROUP_SEPARATOR_CHAR = String.fromCharCode(29);

export const IFRAME_APP_BASE_URL = process.env.NODE_ENV === 'production' ? '/nuacare-iframe' : 'http://localhost:3000/nuacare-iframe';
export const NUACARE_IFRAME_APP_ROUTES ={
    FORMULARY_LIST: 'formulary-list'
}

export const QUEUE_LOCATION_STATUS = {
    ACTIVE: 'ACTIVE',
    BREAK: 'BREAK',
    OFFLINE: 'OFFLINE'
};
